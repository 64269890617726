"use strict";

import Global from "./Global";
import Utility from "./Utility";

class Accessibility {
  constructor() {
    this.observer = null;
    this.init();
  }

  focus(root = document) {
    if (!root) return
    let focused = false;
    Utility.applyToAll(Global.focusableQueryString, (el) => {
      if (focused) return
      if (el.classList.contains('ignore-tab')) return
      if (el.hasAttribute('data-ignore-tab')) return
      if (Utility.elementHasClasses(el, Global.focusableIgnoredClassesList)) {
        el.setAttribute('data-ignore-tab', '');
        return
      }
      focused = true;
      el.focus();
    }, root)
  }

  init() {
    this.ariaHiddenInit(document);
    this.accessibleVideoController();
  }

  disable(el) {
    el && Utility.applyToAll(Global.focusableQueryString, this.removeFocusable, el);
  }

  enable(el) {
    el && Utility.applyToAll(Global.focusableQueryString, this.addFocusable, el);
  }

  addFocusable(el) {
    if (el.classList.contains('ignore-tab')) return
    if (el.hasAttribute('data-ignore-tab')) return
    if (Utility.elementHasClasses(el, Global.focusableIgnoredClassesList)) {
      el.setAttribute('data-ignore-tab', '');
      return
    }
    if (Utility.elementHasClasses(el, ['focusable-element','card3D-container'])){
      el.setAttribute("tabindex", "0");
    } else {
      el.removeAttribute("tabindex");
    }
  }

  removeFocusable(el) {
    if (el.classList.contains('ignore-tab')) return
    if (el.hasAttribute('data-ignore-tab')) return
    if (Utility.elementHasClasses(el, Global.focusableIgnoredClassesList)) {
      el.setAttribute('data-ignore-tab', '');
      return
    }
    el.setAttribute("tabindex", "-1");
  }

  ariaHiddenInit(root = document) {
    let focusables = root.querySelectorAll(
      '[aria-hidden]:not([data-ignore-handle])'
    );
    if (focusables && focusables.length != 0) {
      focusables.forEach(el => this.ariaHiddenElInit(el, root));
    }
  }

  ariaHiddenElInit(el, root = document){
    this.onAriaHiddenChange(el);
    if (root == document) {
      this.initAriaHiddenObserver(el);
    }
  }

  onAriaHiddenChange(el, watchInside = false){
    if (el.hasAttribute('data-ignore-handle')) return
    if (el.getAttribute("aria-hidden") == "true") {
      Utility.applyToAll(Global.focusableQueryString, this.removeFocusable, el);
      if (!el.hasAttribute('data-ignore-inert') && !Utility.elementHasClasses(el, Global.ignoreInertClasses)){
        el.setAttribute("inert", true);
      }
    } else {
      Utility.applyToAll(Global.focusableQueryString, this.addFocusable, el);
      if (el.hasAttribute('inert')){
        el.removeAttribute("inert");
        if (watchInside){
          this.ariaHiddenInit(el);
        }
      }
      window.app_images?.init(el);
    }
  }

  onMutate(mutationList) {
    mutationList.forEach((mutation) => {
      if (mutation.type != "attributes") return;
      this.onAriaHiddenChange(mutation.target, true);
    });
  }

  initAriaHiddenObserver(el) {
    if (!this.observer){
      this.observer = new MutationObserver(this.onMutate.bind(this));
    }
    this.observer.observe(el, {
      childList: false,
      attributes: true,
      attributeFilter: ["aria-hidden"],
    });
  }

  speak(text, priority = "polite") {
    var el = document.createElement("div");
    var id = "speak-" + Date.now();
    el.setAttribute("id", id);
    el.setAttribute("aria-live", priority);
    el.classList.add("sr-only", "aria-speaker");
    document.body.appendChild(el);

    setTimeout(function () {
      document.getElementById(id).innerHTML = text;
    }, 200);

    setTimeout(function () {
        document.body.removeChild(document.getElementById(id));
    }, 2000);
  }

  announce(passedOptions = null) {
    if (!passedOptions) return
    let defaultOptions = {
      context: document.body,
      mode: "assertive",
      message: "",
      delay: 100,
      removeAfter: 5000,
    }

    let options = {...defaultOptions, ...passedOptions};
    if (!options.message) return
    let announcerEl = document.createElement("div");
    let id = `speak-${Date.now()}`;
    announcerEl.setAttribute("id", id);
    announcerEl.setAttribute("aria-live", options.mode);
    announcerEl.classList.add("sr-only", "aria-speaker");
    options.context?.appendChild(announcerEl);
    let announcer =  document.getElementById(id);
    setTimeout(() => {
      if (announcer) {
        announcer.innerHTML = options.message;
      }
    }, Math.max(200, options.delay));

    setTimeout(() => {
      announcer && options.context?.removeChild(announcer);
    }, options.removeAfter);
  }

  accessibleVideoController() {
    let loopVideos = document.querySelectorAll("video[loop][autoplay]");
    if (loopVideos.length == 0) return
    loopVideos.forEach( video => {
      this.generatePlayPauseBtn(video);
    })
  }

  generatePlayPauseBtn(video) {
    if (!video) return
    let target = video;
    if(video.closest('a.top-banner__link') !== null) {
      target = video.closest('a.top-banner__link');
    }
    const btnMarkup = "<button aria-label='Pause video' type='button' class='video-playpause --playing'><span class='css-icon --play-pause --playing'></span></button>";
    const temp = document.createElement('div');
    temp.innerHTML = btnMarkup;
    const btnEl = temp.firstChild;
    target.insertAdjacentElement('afterend', btnEl);
    btnEl.addEventListener('click', (event) => {
      this.handlePlayPauseBtn(event.currentTarget, video);
    });
  }

  handlePlayPauseBtn(button, target) {
    if(button.classList.contains('--playing')) {
      button.classList.replace('--playing', '--paused');
      button.children[0].classList.replace('--playing', '--paused');
      button.setAttribute('aria-label', 'Play video');
      target.pause();
    } else {
      button.classList.replace('--paused', '--playing');
      button.children[0].classList.replace('--paused', '--playing');
      button.setAttribute('aria-label', 'Pause video');
      target.play();
    }
  }
}

export default Accessibility;
