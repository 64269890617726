import BaseField from "./fields/BaseField";
import BooleanField from "./fields/BooleanField";
import Client from "../../base/Client";
import ConditionalFieldsets from "./ConditionalFieldsets";
import CountryField from "./fields/CountryField";
import CreatePasswordField from "./fields/CreatePasswordField";
import DateField from "./fields/DateField";
import EmailField from "./fields/EmailField";
import ExternalValidator from "./ExternalValidator";
import FileField from "./fields/FileField";
import FormInteractions from "./FormInteractions";
import FormRequest from "./FormRequest";
import FrontendValidator from "./FrontendValidator";
import PasswordField from "./fields/PasswordField";
import PredictiveSearchField from "./fields/PredictiveSearchField";
import PromoCodeField from "./fields/PromoCodeField";
import RangeField from "./fields/RangeField";
import Render from "../../base/Render";
import RichTextField from "./fields/RichTextField";
import Saver from "./Saver";
import SelectField from "./fields/SelectField";
import TextareaField from "./fields/TextareaField";
import Typeahead from "./Typeahead";
import Utility from "../../base/Utility";

class Form {
  constructor(el, parent) {
    this.parent = parent;
    this.el = el;
    this.id = this.el.id;
    this.status = "";
    this.frontendValidator = null;
    this.externalValidator = null;
    this.noTransition = false;
    this.dialog = null;
    this.typeaheads = new Map();
    this.appendedFieldErrors = {};
    this.appendedFieldErrorsTemporary = false;
    this.fields = new Map();
    this.isInactive = false;
    this.submitConfig = {};
    this.content = {};
    this.conditionalFieldsets = new Map();
    this.errors = new Map();
    this.hiddenErrors = new Map();
    this.listenersController = new AbortController();
    this.hasLoading = el.hasAttribute("data-has-loading") || false;
    this.isSubmitting = false;
    this.outputElTemplate = el.querySelector(".output-template") || null;
    this.fieldsTotal = new Set();
    this.nonBlocking = el.hasAttribute("data-non-blocking");
    this.requestIsBlocking = el.hasAttribute("data-blocking-request");
    this.outputEl = null;
    this.outputList = null;
    this.outputHeader = null;
    this.loadingCallback = null;
    this.extraRequestParams = {};
    this.validationDisabled = false;
    this.actionLabel = el.getAttribute("data-action-label") || "";
    this.useSelectorLabels =
      this.el.hasAttribute("data-use-selector-labels") || false;
    this.successCallback = null;
    this.topLevelErrors = this.el.hasAttribute("data-top-level-errors");
    this.outputItemTemplate =
      this.el.querySelector(".output-item-template") ||
      document.querySelector(".global-templates .output-item-template") ||
      null;
    this.customSubmitter = null;
    this.customEndpoint = null;
    this.customPayload = null;
    this.loadingTemplate = this.el.querySelector(".loading-template") || null;
    this.successTemplate =
      this.el.querySelector(".success-message-template") || null;
    this.successEl = this.el.querySelector(".form__success") || null;
    this.wrapper = this.el.querySelector(".form__wrapper") || null;
    this.returnFocusEl = null;
    this.notSubmitted = true;
    this.lastRequest = null;
    this.loadingEl = null;
    this.persistLoading = false;
    this.fieldsReady = new Set();
    this.abortController = new AbortController();
    this.requestJSON = this.el.hasAttribute("data-request-json") || false;
    this.submitOnPage = el.hasAttribute("data-submit-on-page") || false;
    this.clearOnSubmit = el.hasAttribute("data-clear-on-submit") || false;
    this.noOutput = el.hasAttribute("data-no-output") || false;
    this.displayInputs = el.hasAttribute("data-display-inputs") || false;
    this.submitDisabled = false;
    this.saver = null;
    this.submitBtns = this.el.querySelectorAll('[type="submit"], .submitter');
    this.successTimer = this.el.hasAttribute("data-success-timer") ? Number(this.el.getAttribute("data-success-timer")) : 3000;
    this.inputTypeMapping = {
      text: BaseField,
      email: EmailField,
      checkbox: BooleanField,
      radio: BooleanField,
      select: SelectField,
      password: PasswordField,
      date: DateField,
      file: FileField,
      range: RangeField,
      country: CountryField,
      "predictive-search": PredictiveSearchField,
      "create-password": CreatePasswordField,
      "textarea": TextareaField,
      "rich-text": RichTextField,
      "promo-code": PromoCodeField,
    };
    // Lifecycle Hooks
    this.onChange = (event, field, formInstance) => { };
    this.onBeforeSubmit = (formInstance) => { };
    this.onLoading = (formInstance) => { };
    this.onValidate = (formInstance, valid) => { };
    this.onFrontendValid = (formInstance) => { };
    this.onFrontendInvalid = (formInstance) => { };
    this.onSubmit = (formInstance) => true;
    this.onRequest = (formRequestInstance) => { };
    this.onResponse = (formInstance) => { };
    this.onComplete = (formInstance) => { };
    this.onSubmitComplete = (formInstance) => { };
    this.onSuccess = (formInstance) => true;
    this.onAfterSuccess = (formInstance) => true;
    this.onAfterSuccessMessage = (formInstance) => true;
    this.onFieldErrorResolved = (fieldInstance) => { };
    // Overrides
    this.overrideSubmitHandler = null;
    // Filters
    this.filterGetter = (data) => data;
    this.filterFormDataGetter = (data) => data;
    this.filterRequest = (data) => data;
    this.filterResponse = (data) => data;
    this.init();
  }

  fieldReady(name) {
    name && this.fieldsReady.add(name);
    this.setFieldStatus();
  }

  fieldUnready(name) {
    name && this.fieldsReady.delete(name);
    this.setFieldStatus();
  }

  enableTransition() {
    this.noTransition = false;
    this.el.classList.remove("--block-transitions");
  }

  disableTransition() {
    this.noTransition = true;
    this.el.classList.add("--block-transitions");
  }

  enableValidation() {
    this.validationDisabled = false;
  }

  disableValidation() {
    this.validationDisabled = true;
  }

  fill(data, trigger = true) {
    if (!data || !(data instanceof Object)) return;
    let triggered = false;
    Object.entries(data).forEach(([key, value]) => {
      if (!value) return;
      if (typeof value != "object" && !(value instanceof Array)) {
        value = String(value);
      }
      if (value instanceof Array) {
        value = value.map(el => String(el).trim())
      }
      if (this.fields.has(key)) {
        let field = this.fields.get(key);
        if (field.type == "select") {
          field.setValue(value, trigger);
        } else if (field.type == "textarea") {
          field.setValue(value, trigger);
        } else if (field.type == "file") {
          field.setValue(value, null, trigger);
        } else if (field.type == "boolean") {
          if (field.getIsGroup()) {
            field.getGroup().forEach((item) => {
              if (value.includes(item.value)) {
                item.checked = true;
                item.setAttribute("checked", "");
                trigger && item.dispatchEvent(new Event("change", { bubbles: true }));
              }
            });
          } else {
            if (value == field.el.value) {
              field.el.checked = true;
              field.el.setAttribute("checked", "");
              trigger && field.el.dispatchEvent(new Event("change", { bubbles: true }));
            }
          }
        } else {
          field.setValue(String(value));
        }
        triggered = true;
      } else {
        let input = this.el.querySelector(
          `input[type="hidden"][name="${key}"], input[type="hidden"][data-key="${key}"]`
        );
        if (input && value) {
          input.value = value;
        }
      }
    });
    if (!triggered && trigger) {
      this.el.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }

  getFormData(asObject = false, forStorage = false) {
    let formData = asObject ? null : new FormData();
    let formObj = {};
    this.fields.forEach((field, key) => {
      if (forStorage && field.el.hasAttribute("data-skip-save")) return
      if (!field.enabled && !forStorage) return;
      if (field.el.hasAttribute("data-key")) {
        key = field.el.getAttribute("data-key");
      }
      switch (field.type) {
        case "select":
          if (
            this.useSelectorLabels ||
            field.el.hasAttribute("data-use-selector-label") ||
            forStorage
          ) {
            formObj[key] = field.getLabel();
            formData?.append(key, field.getLabel());
          } else {
            formObj[key] = field.getValue();
            formData?.append(key, field.getValue());
          }
          break;
        case "rich-text":
          formObj[key] = field.getValue();
          formData?.append(key, field.getValue());
          break;
        case "file":
          formObj[key] = field.getValue(forStorage);
          formData?.append(key, field.getValue());
          break;
        case "date":
          formObj[key] = field.getValue();
          formData?.append(key, field.getValue());
          break;
        case "boolean":
          if (field.getIsGroup()) {
            formObj[key] = [];
            field.getGroup().forEach((el) => {
              if (el.checked && el.value != "all") {
                formObj[key].push(el.value);
                formData?.append(key, el.value);
              }
            });
            if (formObj[key].length == 0) {
              delete formObj[key];
            }
          } else {
            if (field.el.checked) {
              formObj[key] = field.el.value;
              formData?.append(key, field.el.value);
            }
          }
          break;
        default:
          if (asObject && field.el.hasAttribute('data-id') && field.el.closest('[data-id]') && !forStorage) {
            let container = field.el.parentElement.closest('[data-id]');
            let index = Array.prototype.indexOf.call(container.parentNode.children, container);
            let name = 'grouping';
            let key = field.el.hasAttribute('data-key') ? field.el.getAttribute('data-key') : field.truename;
            if (field.el.closest('.table')?.hasAttribute('data-type')) {
              name = field.el.closest('.table')?.getAttribute('data-type');
            }
            if (!formObj.hasOwnProperty(name)) {
              formObj[name] = {};
              formObj[name][index] = {};
            } else if (!formObj[name].hasOwnProperty(index)) {
              formObj[name][index] = {};
            }

            formObj[name][index][key] = field.el.value;
            formData?.append(key, field.el.value);
          } else {
            formObj[key] = field.el.value;
            formData?.append(key, field.el.value);
          }
          break;
      }
    });

    this.el.querySelectorAll('input[type="hidden"]').forEach((el) => {
      if (el.hasAttribute("data-skip-empty") && el.value == "") return;
      if (forStorage && el.hasAttribute("data-skip-save")) return;
      if (el.closest('conditional-field[aria-hidden="true"]') || el.hasAttribute("data-skip")) return;
      if (el.hasAttribute("data-key")) {
        formObj[el.getAttribute("data-key")] = el.value;
        formData?.set(el.getAttribute("data-key"), el.value);
      } else {
        if (el.name == 'get-form-data') {
          let foreignForm = window.app_forms.collection.get(el.value);
          if (foreignForm) {
            let foreignObj = foreignForm.getFormData(true);
            let foreignData = foreignForm.getFormData(false);
            formObj = { ...formObj, ...foreignObj };
            if (formData) {
              for (const pair of foreignData.entries()) {
                formData.append(pair[0], pair[1]);
              }
            }
          }
        } else {
          formObj[el.name] = el.value;
          formData?.set(el.name, el.value);
        }
      }
    });

    if (this.submitConfig.submitter?.hasAttribute('value') && this.submitConfig.submitter?.hasAttribute('name')) {
      let key = this.submitConfig.submitter.getAttribute('name');
      let value = this.submitConfig.submitter.getAttribute('value');
      formObj[key] = value;
      formData?.append(key, value);
    }

    if (forStorage) {
      return asObject ? formObj : formData;
    }

    for (const property in this.extraRequestParams) {
      formObj[property] = this.extraRequestParams[property];
      formData?.set(property, this.extraRequestParams[property]);
    }
    // Add CSRF Protection
    if (this.el.hasAttribute("data-csrf-protect")) {
      let token = Client.getCSRFToken();
      if (token) {
        formObj["csrfmiddlewaretoken"] = token;
        formData?.set("csrfmiddlewaretoken", token);
      }
    }

    // Add Client Timezone Offset
    if (this.el.hasAttribute("data-client-offset")) {
      let offsetMinutes = new Date().getTimezoneOffset();
      let offsetHours = offsetMinutes === 0 ? 0 : -(offsetMinutes / 60);
      formObj["client_utcoffset"] = Math.round(offsetHours);
      formData?.set("client_utcoffset", Math.round(offsetHours));
    }

    this.content = this.filterGetter(formObj);
    return asObject ? this.content : this.filterFormDataGetter(formData);
  }

  clear(clearHidden = false, trigger = false) {
    this.removeErrors();
    this.el.classList.remove("--error", "--complete", "--success");
    this.notSubmitted = true;
    if (clearHidden) {
      this.el.querySelectorAll('input[type="hidden"]').forEach((el) => {
        el.value = "";
      });
    }
    this.fields.forEach((field) => {
      field.clearErrors();
      if (field.type != "boolean") {
        if (field.el.value.trim() != "") {
          if (field.type == "select") {
            field.setValue("");
            trigger && field.onSelect("", "");
          } else {
            field.el.value = "";
          }
        }
      } else {
        if (field.getIsGroup()) {
          field.getGroup().forEach((item) => {
            item.checked = false;
            item.removeAttribute("checked");
          });
        } else {
          if (field.el.checked) {
            field.el.checked = false;
            field.el.removeAttribute("checked");
            if (trigger) {
              field.el.dispatchEvent(new Event("change"));
            }
          }
        }
      }
    });
  }

  setFieldStatus() {
    if (this.fieldsReady.size === this.fieldsTotal.size) {
      this.el.classList.add("--fields-ready");
      this.enableSubmit();
    } else {
      this.el.classList.remove("--fields-ready");
      this.disableSubmit();
    }
  }

  disableSubmit() {
    this.submitDisabled = true;
  }

  enableSubmit() {
    this.submitDisabled = false;
  }

  init() {
    if (!this.loadingTemplate && this.hasLoading) {
      this.loadingTemplate =
        document.querySelector(".global-templates .loading-template") || null;
    }
    if (!this.outputElTemplate && !this.noOutput) {
      this.outputElTemplate =
        document.querySelector(".global-templates .output-template") || null;
    }

    let formRelatedSubmitBtns = []
    this.submitBtns.forEach(btn => {
      if (btn.closest(".form") == this.el) {
        formRelatedSubmitBtns.push(btn);
      }
    })
    this.submitBtns = formRelatedSubmitBtns;

    const dialog = this.el.closest(".dialog");
    if (dialog) {
      this.dialog = window.app_dialogs?.collection.get(dialog.id);
    }
    this.dialog?.el.addEventListener("dialog-opened", this.handleEnter.bind(this), { signal: this.listenersController.signal });
    this.dialog?.el.addEventListener("dialog-after-close", this.handleLeave.bind(this), { signal: this.listenersController.signal });
    this.el.closest(".wizard-panel")?.addEventListener("panel-before-activation", this.handleEnter.bind(this), { signal: this.listenersController.signal });
    this.el.closest(".wizard-panel")?.addEventListener("panel-deactivated", this.handleLeave.bind(this), { signal: this.listenersController.signal });
    this.el.closest(".tabs__panel")?.addEventListener("tab-activated", this.handleEnter.bind(this), { signal: this.listenersController.signal });
    this.el.closest(".tabs__panel")?.addEventListener("tab-deactivated", this.handleLeave.bind(this), { signal: this.listenersController.signal });
    this.applyFormAdjustments();
    this.disableSubmit();
    this.initTypeaheads();
    this.initForm();
    this.initFields();
    this.initEdit();
    this.initInfoBars();
    this.updateBirthday();
    this.initConditionalFieldsets();
    this.initSaver();
    new FormInteractions(this);
    this.status = "init";
    this.el.classList.add("--init");
    Client.dispatchEvent("form-init", {}, this.el);
    if (this.fieldsTotal.size == 0) {
      this.enableSubmit();
    }
    // TODO Remove this and refactor ApplePayment to include within ExternalValidation sequence
    this.initApplePaySupport();
  }

  // TODO: If there will be more adjusmtents like this please export this to a module FormAdjustments.js
  applyFormAdjustments() {
    let dateAdjust = this.el.querySelector(".form-adjust-date");
    if (dateAdjust) {
      let setDate = new Date(dateAdjust.value);
      let today = new Date();
      if (dateAdjust.hasAttribute("data-is-today") || setDate < today) {
        dateAdjust.value = today.toISOString().split('T')[0];
      }
    }
  }

  initApplePaySupport() {
    let applePayTrigger = this.el.querySelector(`button[type="submit"][name="gateway"][value="applepay"]`);
    if (!applePayTrigger) return
    applePayTrigger.addEventListener("click", (e) => {
      e.preventDefault();
      // Validation
      this.loading();
      const frontendIsValid = this.validate();
      if (frontendIsValid) {
        // Frontend Valid
        this.syncErrors();
        this.renderErrors();
        if (window.AGPayment) {
          window.AGPayment?.submitApplePayDataForm(this);
        } else {
          console.error("no AGPayment found");
        }
      } else {
        // Frontend Invalid
        this.complete("error");
      }
    }, { signal: this.listenersController.signal })
  }

  initHardwire() {
    if (!this.el.hasAttribute('data-hardwire')) return
    this.fields.forEach(field => {
      if (field.type != 'text') return
      field.el.addEventListener('keypress', (e) => {
        if (e.key == 'Enter') {
          this.el.dispatchEvent(new Event('submit', { cancelable: true }));
          e.preventDefault();
        }
      }, { signal: field.abortController.signal })
    })
  }

  initTypeaheads() {
    const typeaheads = this.el.querySelectorAll('.typeahead');
    typeaheads.forEach(typeahead => {
      typeahead.id = typeahead.id == '' ? `typeahead-instance` : typeahead.id;
      Utility.fixDuplicates(typeahead);
      this.typeaheads.set(typeahead.id, new Typeahead(typeahead, this));
    })
  }

  initSaver() {
    ["session", "local", "page", "cookie"].forEach(type => {
      if (this.el.hasAttribute(`data-save-${type}`)) {
        let key = this.el.getAttribute(`data-save-${type}`) || `gs-${type}`;
        this.saver = new Saver(key, type, this, this.el.getAttribute("data-save-exclusive"));
        this.saver.load(["photo"]);
      }
    });
  }

  handleLeave() {
    this.abortController.abort();
    this.abortController = new AbortController();
    this.removeLoading();
    setTimeout(() => {
      this.el.classList.remove("--error");
      this.persistLoading = false;
      this.notSubmitted = true;
      this.setFieldStatus();
      this.removeErrors();
    }, 100);
  }

  handleEnter() {
    // Nothing
  }

  syncErrors() {
    if (this.frontendValidator) {
      this.errors = new Map(this.frontendValidator.forwardedErrors);
    } else {
      this.errors = new Map();
    }
    this.hiddenErrors = new Map();
    this.fields.forEach((field, fieldName) => {
      let first = true;
      field.formErrors.forEach((error) => {
        if (first) {
          this.addError(fieldName, error);
          first = false;
        }
      });
    });
  }

  initConditionalFieldsets() {
    const conditionalFieldsets = this.el.querySelectorAll(
      ".conditional-fields"
    );
    if (!conditionalFieldsets || conditionalFieldsets.length == 0) return;
    conditionalFieldsets.forEach((el) => {
      this.conditionalFieldsets.set(
        el.dataset.name ||
        `conditional-fields-${this.conditionalFieldsets.size}`,
        new ConditionalFieldsets(
          el,
          el.dataset.name ||
          `conditional-fields-${this.conditionalFieldsets.size}`,
          this
        )
      );
    });
  }

  addError(fieldName, error) {
    if (this.topLevelErrors) {
      this.errors.set(fieldName, error);
    } else {
      if (["server", "internal", "abort"].includes(fieldName)) {
        this.errors.set(fieldName, error);
      } else {
        this.hiddenErrors.set(fieldName, error);
      }
    }
  }

  removeErrors() {
    this.errors = new Map();
    this.hiddenErrors = new Map();
    this.outputChange();
    this.renderErrors();
    this.fields.forEach((field) => {
      field.clearErrors();
      field.formErrors = new Map();
      field.errors = new Map();
    });
  }

  removeError(fieldName) {
    this.errors.delete(fieldName);
    this.hiddenErrors.delete(fieldName);
  }

  renderErrors() {
    if (this.outputEl && this.outputItemTemplate) {
      this.errors.forEach((value, key) => {
        const el = this.outputEl.querySelector("#" + key + "-error");
        if (el) {
          if (el.innerText.trim() != value) {
            el.firstElementChild.remove();
            el.appendChild(
              Utility.fragmentFromString(
                `<a href="#${this.fields.get(key)?.id || ""}">${value}</a>`
              )
            );
          }
          el.classList.add("--new");
        } else {
          this.renderError(value, key);
        }
      });
      this.clearErrors();
    }
  }

  renderError(value, key) {
    if (!this.outputItemTemplate || !this.outputEl) return;
    const fragment = this.outputItemTemplate.content.cloneNode(true);
    let inner = Utility.stringFromFragment(fragment);
    inner = inner.replace(/{key}/g, `${this.id}-${key}-error`);
    inner = inner.replace(/{classes}/g, "--new");
    if (["server", "internal", "abort"].includes(key)) {
      inner = inner.replace(/{error}/g, `<span>${value}</span>`);
    } else {
      inner = inner.replace(
        /{error}/g,
        `<a href="#${this.fields.get(key)?.id || ""}">${value}</a>`
      );
    }
    this.outputList.appendChild(Utility.fragmentFromString(inner));
  }

  clearErrors(keys = []) {
    if (keys.length == 0) {
      const errors = this.outputEl?.querySelectorAll(".form__error");
      if (!errors || errors.length == 0) return;
      errors.forEach((error) => {
        if (error.classList.contains("--new")) {
          error.classList.remove("--new");
        } else {
          error.remove();
        }
      });
    } else {
      keys.forEach((key) => {
        if (this.errors.has(key)) {
          this.errors.delete(key);
        }
        if (this.hiddenErrors.has(key)) {
          let field = this.fields.get(key);
          field?.handleErrorsResolved();
          this.hiddenErrors.delete(key);
        }
        if (this.appendedFieldErrors.hasOwnProperty(key)) {
          delete this.appendedFieldErrors[key];
        }
        this.outputEl?.querySelector(`#${this.id}-${key}-error`)?.remove();
      });
    }
    this.setOutputClasses();
  }

  setOutputClasses() {
    if (this.errors.size != 0) {
      this.outputEl?.classList.remove("--empty");
    } else {
      this.outputEl?.classList.add("--empty");
    }
  }

  handleFieldErrorResolved(field) {
    this.onFieldErrorResolved(field);
  }

  reassignErrors() {
    this.errors.forEach((error, key) => {
      let field = this.fields.get(key);
      if (field && field.errors.size == 0) {
        field.validate("submit");
      }
    });
    this.hiddenErrors.forEach((error, key) => {
      let field = this.fields.get(key);
      if (field && field.errors.size == 0) {
        field.validate("submit");
      }
    });
    Object.entries(this.appendedFieldErrors).forEach(([key, value]) => {
      this.fields.get(key)?.addServerError(value, this.appendedFieldErrorsTemporary);
    });
  }

  destroy() {
    this.fields.forEach((field, key) => {
      field.destroy();
      this.fields.delete(key);
    });
  }

  initFields() {
    this.fieldsTotal = new Set();
    let fieldsPersisted = new Set();
    if (this.fields.size !== 0) {
      this.fields.forEach((field, key) => {
        if (!field.field.classList.contains("--persistent")) {
          field.destroy();
          this.fields.delete(key);
        } else {
          fieldsPersisted.add(field.truename);
        }
      });
    }
    this.fieldsReady = fieldsPersisted;
    const els = this.el.querySelectorAll(".field");
    if (!els || els.length == 0) return;
    els.forEach(el => {
      let controller = el.querySelector("input:not(.--ignore), textarea:not(.--ignore)");
      if (controller) {
        this.fieldsTotal.add(controller.name.replace("-clone", ""))
      }
    })
    els.forEach(this.initField.bind(this));
    this.reassignErrors();
    this.initHardwire();
  }

  initField(el) {
    if (
      el.classList.contains("--init-as-group") &&
      !el.classList.contains("--group-lead")
    ) {
      this.fieldReady();
      return;
    }
    const controller = el.querySelector(
      "input:not(.--ignore), textarea:not(.--ignore)"
    );
    if (!controller) return
    const controllerName = controller.name.replace("-clone", "");
    if (this.fields.has(controllerName)) {
      if (el.classList.contains("--persistent")) return
      this.fields.get(controllerName).destroy();
    }
    controller.id = controller.id || `${this.el.id}-${controller.name}`;
    let controllerType = controller.getAttribute("data-type") || controller.type || 'text';
    if (controller.tagName == 'TEXTAREA') {
      controllerType = controller.getAttribute("data-type") || controller.type || 'textarea';
    }
    const FieldConstructor = this.inputTypeMapping[controllerType] || BaseField;
    this.fields.set(controller.name, new FieldConstructor(controller, this));
  }

  updateBirthday() {
    const birthdayField = this.el.querySelector(".birthday-text");
    if (!birthdayField) return;
    const birthMonth = this.el.querySelector("#account-update-bday-month");
    const birthDay = this.el.querySelector("#account-update-bday-day");
    if (!birthDay || !birthMonth || !birthDay.value || !birthMonth.value)
      return;
    birthdayField.value = birthMonth.value + " " + birthDay.value;
  }

  initEdit() {
    const button = this.el.querySelector(".edit-form");
    if (!button) return;
    const birthdayField = this.el.querySelector(".--birthday-text");
    const birthdaySelect = this.el.querySelector(".--birthday");
    if (!birthdayField || !birthdaySelect) return;
    button.addEventListener("click", () => {
      if (this.el.classList.contains("--display-view")) {
        this.el.classList.remove("--display-view");
        this.el.classList.add("--edit-view");
      }

      const edits = document.querySelectorAll(".--can-update");
      edits.forEach((element) => {
        element.removeAttribute("readonly");
      });

      birthdayField.classList.add("hidden");
      birthdaySelect.classList.remove("hidden");
    }, { signal: this.listenersController.signal });
  }

  composeErrorMessage(errorCount = 0) {
    if (!errorCount) return ""
    let extra = "";
    let reason = "request";
    let topLevelError = this.el.querySelector(".form__errors .form__error");
    if (this.frontendValidator && !this.frontendValidator.valid) {
      reason = "validation";
    }
    if (this.hiddenErrors.size > 1) {
      if (reason == "validation") {
        extra = ` for ${Utility.counter(errorCount, "field")} total, correct them and retry`;
      } else {
        extra = ` with ${Utility.counter(errorCount, "field error")}`;
      }
    } else if (this.hiddenErrors.size == 1) {
      extra = `, correct field error and retry`;
    }
    if (reason == "validation") {
      if (!topLevelError) {
        return `Form validation failed${extra}.`
      }
      return `Form validation failed.`
    }
    return `${this.actionLabel || "Submit"} request failed${extra}.`;
  }

  outputChange(message = "", alert = false) {
    const totalErrors = this.errors.size + this.hiddenErrors.size;
    this.setOutputClasses();
    if (this.outputHeader) {
      this.outputEl?.setAttribute("aria-live", alert ? "assertive" : "polite");
      this.outputHeader.innerText = message || this.composeErrorMessage(totalErrors);
    }
  }

  renderErrorOutput() {
    if (!this.outputElTemplate) return;
    let rendered = Render.interpolateTemplate(this.outputElTemplate, {
      "class-status": "--empty",
      "id": this.el.id,
    })
    let placer = this.el.querySelector(".form__output-placer:not([data-form])");
    if (!placer) {
      placer = document.querySelector(`.form__output-placer[data-form="${this.el.id}"]`)
    }
    if (placer) {
      placer.parentElement.replaceChild(rendered, placer);
    } else {
      this.el.insertBefore(rendered, this.el.firstElementChild);
    }
    this.outputEl = document.querySelector(`.form__errors[data-form="${this.el.id}"]`) || this.el.querySelector(`.form__errors`);
    this.outputList =
      this.outputEl?.querySelector(".form__errors-list") ||
      this.outputEl?.querySelector("ul");
    this.outputHeader = this.outputEl?.querySelector("header");
    Utility.addResizeObserver(this.outputEl, null);
  }

  initForm() {
    this.el.setAttribute("novalidate", "novalidate");
    this.el.addEventListener("submit", this.submitHandler.bind(this), { signal: this.listenersController.signal });
    this.el.addEventListener("customSubmit", this.submitHandler.bind(this), { signal: this.listenersController.signal });
    this.el.addEventListener("change", this.changeHandler.bind(this), { signal: this.listenersController.signal });
    this.renderErrorOutput();
    this.renderLoading();
  }

  initInfoBars() {
    this.el.querySelectorAll(".form__info")?.forEach((infoEl, counter) => {
      if (infoEl.closest(".form") != this.el) return
      infoEl.id = infoEl.id || `${this.el.id}-info-${counter}`;
      if (infoEl.classList.contains("--animate")) {
        Utility.addResizeObserver(infoEl, null, "--info-bar-height");
      }
      infoEl.getAttribute("data-for")?.split(",")?.forEach(token => {
        if (token == "form") {
          this.el.setAttribute("aria-describedby", infoEl.id);
          return
        }
        if (!token.includes(":")) {
          let field = this.fields.get(token);
          if (field) {
            field.addDescription(infoEl.id);
          } else {
            let target = this.el.querySelector(`[name="${token}"], [data-name="${token}"]`);
            target?.setAttribute("aria-describedby", infoEl.id);
          }
        } else {
          let key = token.split(":")[0];
          let value = token.split(":")[1];
          let field = this.fields.get(key);
          if (field.type == "boolean") {
            field.addDescription(infoEl.id, value);
          } else {
            field.addDescription(infoEl.id);
          }
        }
      })
    })
  }

  changeHandler(e) {
    if (this.saver) {
      if (e.target.classList.contains("form")) {
        if (!this.isInactive) {
          this.saver?.save(null, this.el.getAttribute("data-save-clear-regex") || null);
        }
        return
      }
      if (!e?.target?.name) return
      const field = this.fields.get(e.target.name.replace("-clone", ""));
      if (!field) return
      this.onChange(e, field, this);
      if (field.type != "file" || field.fileBuffer) {
        if (!this.isInactive && !field.el.hasAttribute("data-skip-save")) {
          this.saver?.save(null, this.el.getAttribute("data-save-clear-regex") || null);
        }
      }
      field?.forwardChange(e);
    } else {
      if (!e?.target || e.target.tagName == "FORM") return
      const field = this.fields.get(e.target?.name?.replace("-clone", ""));
      if (!field) return;
      field?.forwardChange(e);
    }
  }

  renderLoading() {
    this.loadingEl = this.el.querySelector(".form__loading") || null;
    if (!this.loadingEl && this.loadingTemplate) {
      const fragment = this.loadingTemplate.content.cloneNode(true);
      this.el.insertBefore(fragment, this.el.firstElementChild);
      this.loadingEl = this.el.querySelector(".form__loading") || null;
    }
    if (this.loadingEl) {
      window.app_accessibility?.ariaHiddenElInit(this.loadingEl);
    }
  }

  validate() {
    this.appendedFieldErrors = {};
    this.errors = new Map();
    this.hiddenErrors = new Map();
    this.frontendValidator = new FrontendValidator(this);
    const result = this.frontendValidator.validate();
    if (this.outputHeader) {
      this.outputHeader.innerText = ``;
    }
    this.onValidate(this, result);
    return result;
  }

  loading() {
    this.disableSubmit();
    this.el.classList.remove("--error", "--success", "--internal", "--abort", "--server-error", "--cancelled");
    this.el.classList.add("--loading");
    this.submitConfig.submitter?.classList.add('--loading');
    this.onLoading(this);
    if (this.loadingCallback) {
      this.loadingCallback();
    }
    if (this.loadingEl) {
      if (!this.returnFocusEl && window.app_page?.wasKeyboardEvent) {
        this.returnFocusEl = document.activeElement;
      }
      this.loadingEl.setAttribute("aria-hidden", false);
      setTimeout(() => {
        this.loadingEl.firstElementChild?.focus({ preventScroll: true });
      }, 0);
    }
  }

  removeLoading() {
    this.el.classList.remove("--loading");
    this.submitBtns.forEach(btn => btn.classList.remove('--loading'));
    this.submitConfig.submitter?.classList.remove('--loading');
    this.loadingEl?.setAttribute("aria-hidden", true);
  }

  afterSubmit() {
    this.onSubmitComplete(this);
    this.isSubmitting = false;
    if (this.requestIsBlocking && this.dialog) {
      this.dialog.isSubmitting = false;
    }
    this.notSubmitted = false;
    this.enableSubmit();
    if (!this.persistLoading) {
      this.removeLoading();
    } else {
      this.persistLoading = false;
    }
    this.submitConfig.event = null;
    if (this.loadingEl) {
      if (this.returnFocusEl && window.app_page?.wasKeyboardEvent) {
        if (this.returnFocusEl.classList.contains("filters__reset")) {
          this.el
            .querySelector(':not([aria-hidden="false"]) input:not([hidden])')
            ?.focus({ preventScroll: true });
        } else if (this.returnFocusEl.classList.contains("filter-remove")) {
          const removeFilter = this.el.querySelector(".filter-remove");
          if (removeFilter) {
            removeFilter.focus({ preventScroll: true });
          } else {
            this.el
              .querySelector(':not([aria-hidden="false"]) input:not([hidden])')
              ?.focus({ preventScroll: true });
          }
        } else if (this.returnFocusEl.classList.contains("filter-trigger")) {
          const filterTrigger = document.querySelector(
            `input[name="${this.returnFocusEl.name}"][value="${this.returnFocusEl.value}"]`
          );
          filterTrigger?.focus({ preventScroll: true });
        } else {
          this.returnFocusEl.focus({ preventScroll: true });
        }
        this.returnFocusEl = null;
      }
    }
  }

  submitGuarded() {
    // Prevent native submit on form if the form is not set to be submitted on page
    if (!this.submitOnPage && this.submitConfig.event && this.submitConfig.event instanceof Event) {
      this.submitConfig.event.preventDefault();
    }
    // GUARD: Readonly field triggered
    if (this.submitConfig.event?.target?.hasAttribute("readonly")) return 'Readonly field triggered submit'
    // GUARD: Disabled submit
    if (this.submitDisabled) return 'Disabled submit';
    // GUARD: Repeated submit
    if (this.isSubmitting || this.dialog?.isSubmitting) return 'Repeated submit';
    // GUARD: Endpoint is null
    if (!this.submitOnPage && !this.el.hasAttribute("action") && !this.customEndpoint && !this.submitConfig.customEndpoint && !this.el.hasAttribute('data-endpoint')) return 'Endpoint is null';
    // GUARD: Blocked by attribute
    if (this.el.hasAttribute("data-block-submit")) return 'Blocked by attribute';
    // GUARD: Alternative submitter present
    if (!this.submitConfig.avoidOverride && typeof this.overrideSubmitHandler === "function") {
      this.overrideSubmitHandler(this.submitConfig.event, this.submitConfig.customEndpoint, this.submitConfig.customPackage);
      return 'called overrideSubmitHandler()';
    }

    // GUARDS PASSED
    return 'Passed';
  }

  beforeSubmit() {
    this.onBeforeSubmit(this);
    this.abortController?.abort();
    this.abortController = new AbortController();
    this.isSubmitting = true;
    if (this.requestIsBlocking && this.dialog) {
      this.dialog.isSubmitting = true;
    }
    this.loading();
  }

  getSubmitter(e = null) {
    let submitter = e?.submitter || this.customSubmitter;
    // fix for Safari to support submitter
    if (!submitter) {
      let target = window.app_page?.lastClickEvent?.target;
      submitter = target && target.tagName == 'BUTTON' ? target : null;
    }
    // if no submitter was found select the first submit button of the form
    if (!submitter && this.submitBtns) {
      submitter = this.submitBtns[0]
    }

    return submitter;
  }

  silentSubmit() {
    let submitter = this.getSubmitter();
    this.submitConfig = { event: null, submitter, customEndpoint: false, customPackage: false, avoidOverride: false, ignoreValidation: true };
    let guard = this.submitGuarded();
    if (guard != 'Passed') return
    this.beforeSubmit();
    this.onFrontendValid(this);
    this.syncErrors();
    this.submit();
  }

  submitHandler(
    e = null,
    customEndpoint = null,
    customPackage = null,
    avoidOverride = false,
    ignoreValidation = false
  ) {
    // Preparation
    let submitter = this.getSubmitter(e);
    this.submitConfig = { event: e, submitter, customEndpoint, customPackage, avoidOverride, ignoreValidation };
    let guard = this.submitGuarded();
    if (guard != 'Passed') return
    this.beforeSubmit();

    // Validation
    const frontendIsValid = this.submitConfig.ignoreValidation ? true : this.validate();
    if (frontendIsValid) {
      // Frontend Valid
      this.onFrontendValid(this);
      this.syncErrors();
      this.renderErrors();
      if (!this.submitOnPage) {
        this.externalValidator = new ExternalValidator(this);
        if (this.externalValidator.isRequired) {
          this.externalValidator.validateAndSubmit();
        } else {
          this.submit();
        }
      }
    } else {
      this.onFrontendInvalid(this);
      // Frontend Invalid
      if (this.submitOnPage) {
        this.submitConfig.event.preventDefault();
      }
      this.complete("validation-error");
    }
  }

  submit() {
    if (!this.onSubmit(this)) return
    this.outputChange("Submitting...", true);
    new FormRequest(this, this.responseHandler.bind(this))
  }

  responseHandler(request) {
    this.isSubmitting = false;
    this.customPayload = null;
    this.customEndpoint = null;
    this.customSubmitter = null;
    if (this.requestIsBlocking && this.dialog) {
      this.dialog.isSubmitting = false;
    }
    this.lastRequest = request;

    if (!request.response) {
      this.addError(
        "server",
        "Unexpected error occurred. Please try again later"
      );
      this.complete("error-server");
      return;
    }

    if (
      request.response.hasOwnProperty("status") &&
      request.response.status == "success"
    ) {
      this.lastRequest.response = this.filterResponse(
        this.lastRequest.response
      );
    }
    this.onResponse(this);
    if (request.customEndpoint) {
      this.complete("success");
      return;
    }
    if (request.response.hasOwnProperty("status")) {
      switch (request.response.status) {
        case "custom":
          this.complete("custom");
          break;
        case "success":
          this.successHandler();
          this.complete("success");
          break;
        case "error":
          if (request.response.hasOwnProperty("error")) {
            this.complete("error-server");
            this.appendErrorsToFields(request.response.error?.details);
          } else if (request.response?.data?.redirect_url) {
            window.location = request.response.data.redirect_url;
            this.complete("error-server");
          } else {
            this.addError("server", request.response.data.message);
            this.complete("error-server");
          }
          break;
        case "abort":
          this.complete("abort");
          break;
        case -1:
          this.complete("error-server");
          this.appendErrorsToFields(request.response?.errors);
          break;
        default:
          this.addError(
            "server",
            "Unexpected error occurred. Please try again later."
          );
          this.complete("error-server");
          break;
      }
    } else if (request.response.hasOwnProperty("error")) {
      if (request.response.error.hasOwnProperty('details')) {
        this.complete("error-server");
        this.appendErrorsToFields(request.response.error.details);
      } else {
        this.addError(
          "server",
          "Unexpected error occurred. Please try again later."
        );
        this.complete("error-server");
      }
    }
  }

  appendErrorsToFields(errors, temporary = false) {
    if (!errors) return;
    errors = this.filterBackendErrors(errors);
    this.appendedFieldErrors = errors;
    this.appendedFieldErrorsTemporary = temporary;
    let atLeastOneFieldError = false;
    Object.entries(this.appendedFieldErrors).forEach(([key, value]) => {
      if (this.fields.get(key)) {
        atLeastOneFieldError = true;
        this.fields.get(key).addServerError(value, this.appendedFieldErrorsTemporary);
      }
    });
    if (!atLeastOneFieldError) {
      this.addError(
        "server",
        "Request error occurred. Please try again later"
      );
      this.renderErrors();
    }
  }

  filterBackendErrors(errors) {
    let filteredErrors = errors;
    if (filteredErrors.hasOwnProperty('expiration_error_attach')) {
      filteredErrors._cc_exp_year = filteredErrors.expiration_error_attach;
      delete filteredErrors.expiration_error_attach;
    }
    return filteredErrors;
  }

  complete(message) {
    this.status = message;
    this.el.classList.add("--" + message);
    this.onComplete(this);
    this.afterSubmit();
    Client.dispatchEvent('submitComplete', { message }, this.el);
    if (message == "cancelled") return;
    if (!["external-validation-error", "error-server"].includes(message)) {
      this.syncErrors();
    }
    this.renderErrors();
    if (message != "success") {
      setTimeout(this.afterComplete.bind(this), 200);
    }
    this.el.dispatchEvent(
      new CustomEvent("afterFormSubmit", {
        bubbles: true,
        detail: this,
      })
    );
  }

  afterComplete() {
    let firstInvalid = this.el.querySelector('[aria-invalid="true"]');
    if (!firstInvalid) {
      if (this.errors.size != 0 && this.outputEl) {
        Client.scrollTo(this.outputEl, 100, "smooth", true, this.el.closest(".dialog__wrapper"));
        this.outputChange()
      }
      return
    }

    let field = this.fields.get(firstInvalid.name.replace('-clone', ''));
    if (field && field.type != 'boolean') {
      field.setFocus();
    } else {
      firstInvalid.focus();
    }
    setTimeout(() => {
      this.outputChange()
    }, 500);
  }

  successHandler() {
    window.app_services?.track("form-successful-submit", this);
    // onSuccess Hook can return false that will cause the form not to display the
    // success mesage or to react to the server response
    if (!this.onSuccess(this)) return;

    if (this.el.hasAttribute("data-refresh") || this.lastRequest.response?.data?.redirect_url) {
      this.persistLoading = true;
    }
    if (!this.successTemplate) {
      this.el.classList.add("--no-success-output");
    }
    this.renderSuccessMessage();
    this.renderSuccessBoxMessage();
    this.afterSuccess();
  }

  renderSuccessMessage() {
    let data = this.lastRequest?.response?.data || {};
    if (!this.successTemplate || !this.successEl) return;
    if (this.el.classList.contains("--no-success-output")) return;
    if (this.successEl.firstElementChild) return;

    let show_taxes = "";
    if (data.has_taxes != true) {
      show_taxes = "hidden"
    }

    let rendered = Render.interpolateTemplate(this.successTemplate, {
      'name': data.customer?.name || '',
      'message': data.message || '',
      'price': data.price || '',
      'exp_date': data.exp_date || '',
      'confirm_id': data.confirm_id || '',
      'show_taxes': show_taxes
    })

    this.successEl.appendChild(rendered);

    if (!this.displayInputs && !this.el.classList.contains("--success-above")) {
      this.wrapper?.setAttribute("aria-hidden", true);
    }
    this.successEl.firstElementChild?.setAttribute("tabindex", "-1");
    this.successEl.firstElementChild?.focus();
    setTimeout(() => {
      this.successEl?.querySelectorAll(".carousel")?.forEach(carouselEl => {
        window.app_carousels.add(carouselEl);
      });
      window.app_images?.loadImages(this.successEl);
    })
    if (this.nonBlocking) {
      setTimeout(() => {
        this.successEl.firstElementChild?.classList.add("--removing");
      }, Math.min(Math.max(this.successTimer - 500, 0), 400));
      setTimeout(() => {
        this.wrapper?.setAttribute("aria-hidden", false);
        this.el.classList.remove("--success");
        this.successEl.firstElementChild?.classList.add("--removed");
        this.removeLoading();
        if (this.clearOnSubmit) {
          this.el.reset();
        }
      }, this.successTimer);
      setTimeout(() => {
        this.successEl.firstElementChild?.remove();
      }, this.successTimer + 200);
    }
  }

  renderSuccessBoxMessage(announce = true) {
    let box = this.el.querySelector(".form__success-box");
    let message = this.el.getAttribute("data-success-message");
    if (!box || !message) return
    box.classList.add("--success");
    box.innerHTML = `<p>${message}</p>`;
    window.app_accessibility.announce({ message });
  }

  afterSuccess() {
    // This method handles the effects of a succesful submit
    if (!this.onAfterSuccess(this)) return;
    let data = this.lastRequest.response?.data || {};
    // if the response has wizard key, handle it
    if (data.hasOwnProperty("wizard")) {
      if (this.dialog) {
        this.dialog.main.deactivate(this.dialog.name);
        window.app_wizards?.get(data.wizard.name)?.step(data.wizard.step);
      }
    }

    // TODO: Remove this bit from here, add it to FormInteractions.js with updateBirthday
    if (this.el.classList.contains("--edit-view")) {
      this.el.classList.remove("--edit-view");
      this.el.classList.add("--display-view");
      const edits = document.querySelectorAll(".--can-update");
      edits.forEach((element) => {
        element.readOnly = true;
      });
      this.updateBirthday();

      const birthdayField = this.el.querySelector(".--birthday-text");
      const birthdaySelect = this.el.querySelector(".--birthday");
      if (!birthdayField || !birthdaySelect) return;

      birthdayField.classList.remove("hidden");
      birthdaySelect.classList.add("hidden");
    }

    // LAST STEP: If response has redirect_url or the form is set to data-refresh handle redirect or reload.
    // If the form features a Success Message then wait the success duration before redirecting or reloading.
    setTimeout(() => {
      if (!this.onAfterSuccessMessage(this)) return;
      if (data.hasOwnProperty("redirect_url")) {
        window.location = data.redirect_url;
      } else if (this.el.hasAttribute("data-refresh")) {
        window.location.reload();
      }
      if (this.el.hasAttribute("data-close-after-success") && this.dialog) {
        window.app_dialogs.deactivate(this.dialog.name);
      }
    }, this.successTemplate ? this.successTimer : 0)
  }

  destroy() {
    this.fields.forEach(field => field.destroy());
    this.listenersController.abort();
    this.parent.collection.delete(this.id);
  }

}

export default Form;
