import AppleProvider from "./AppleProvider";
import FacebookProvider from "./FacebookProvider";
import GoogleProvider from "./GoogleProvider";
import SSOSession from "./SSOSession";

class SSOManager {

  constructor() {
    this.providers = new Map();
    this.constructors = {
      "google": GoogleProvider,
      "facebook": FacebookProvider,
      "apple": AppleProvider,
    };
    this.session = null;
    this.init();
  }

  init() {
    if (window.app_env?.sso_config) {
      for (let provider in app_env.sso_config) {
        if (!this.constructors.hasOwnProperty(provider)) continue
        let Constructor = this.constructors[provider];
        this.providers.set(provider, new Constructor(app_env.sso_config[provider], this))
      }
    }
    window.app_listeners?.add('click', `sso-trigger`, this.clickHandler.bind(this));
    this.handleWelcome();
  }

  handleWelcome() {
    let welcome = window.app_storage?.get("ag-affiliate-welcome", "session");
    if (welcome) {
      if (!["payemnt", "pof"].includes(window.app_env.page_type)) {
        window.app_dialogs?.open("affiliation-dialog");
      }
      window.app_storage?.delete("ag-affiliate-welcome", "session");
    }
  }

  clickHandler(e) {
    let target = e.real_target || e.target;

    if (!target.classList.contains("sso-auth__trigger")) return
    let form = target.closest(".form")?.getInstance("forms");
    if (form && !form.isSubmitting && !this.session?.locked) {
      form.removeErrors();
      this.session?.end();
      this.session = new SSOSession(this.providers.get(target.value), form, e);
    } else {
      console.warn("Another session is running or form not found");
    }
  }

  async handleAuthSuccess(sso_provider, sso_token, sso_extra="") {
    this.session.handleAuthSuccess(sso_provider, sso_token, sso_extra);
  }

  handleAuthFail(message = "") {
    this.session?.fail(message)
  }

}

export default SSOManager;