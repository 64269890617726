import Client from "../base/Client";
import Global from "../base/Global";
import Utility from "../base/Utility";

class Dialog {

  constructor(el, parent) {
    this.el = el;
    this.parent = parent;
    this.name = el.id;
    this.content = el.querySelector('.dialog__content, .aside__content');
    this.isActive = false;
    this.isSubmitting = false;
    this.dialogSpeed = 200;
    this.triggerEl = null;
    this.isSidelined = false;
    this.hasScrollbar = el.hasAttribute('data-has-scrollbar');
    this.isPrompt = el.hasAttribute('data-prompt');
    this.isAside = el.classList.contains('aside');
    this.firstFocus = el.querySelector(Global.focusableQueryString);
    this.isBlocking = el.hasAttribute('data-blocking') || false;
    this.hiddenDefault = this.el.hasAttribute("data-hidden");
    this.abortController = new AbortController();
    this.sequentialActivation = this.el.hasAttribute("data-sequential");
    this.refreshOnClose = this.el.hasAttribute("data-refresh-on-close");
    // Hooks
    this.onClose = (instance, e) => { };
    this.onAfterClose = (instance, e) => { };
    this.onOpen = (instance, e) => { };
    this.onClick = (e) => { };
    // Initialization
    this.init()
  }

  init() {
    const closes = this.el.querySelectorAll('.dialog__wrapper, .aside__wrapper');
    if (closes && closes.length != 0) {
      closes.forEach(close => {
        if (close.classList.contains('dialog__wrapper') || close.classList.contains('aside__wrapper')) {
          close.addEventListener(Client.isTouch() ? "touchstart" : "mousedown", (e) => {
            if (e.composedPath().includes(this.content)) {
              close.classList.add('--mousedown-inside');
            }
          }, { signal: this.abortController.signal })
          close.addEventListener(Client.isTouch() ? "touchend" : "mouseup", (e) => {
            setTimeout(() => {
              close.classList.remove('--mousedown-inside');
            }, 0)
          }, { signal: this.abortController.signal })
        }
      })
    }
    window.app_listeners?.add('click', `${this.el.id}-dialog-click`, this.clickHandler.bind(this))
  }

  clickHandler(e) {
    if (!this.el.contains(e.target)) return
    const wrapper = this.el.querySelector('.dialog__wrapper, .aside__wrapper');
    if (!wrapper) return
    if (e.target.classList.contains('dialog__close')
      || e.target.classList.contains('aside__close')
      || e.target.classList.contains('dialog__wrapper')
      || e.target.classList.contains('aside__wrapper')) {
      if (e.target == wrapper) {
        if (!wrapper.classList.contains('--mousedown-inside')) {
          if (e.target.classList.contains('--skip-focus-return')) {
            this.triggerEl = null;
          }
          this.parent.deactivate(this.name, e);
        }
        wrapper.classList.remove('--mousedown-inside');
      } else {
        if (e.target.classList.contains('--skip-focus-return')) {
          this.triggerEl = null;
        }
        this.parent.deactivate(this.name, e);
      }
    }
    wrapper?.classList.remove('--mousedown-inside');
    this.onClick(e);
  }

  trap(enable = true) {
    if (enable) {
      window.app_listeners?.add('keydown', `dialog-trap-${this.name}`, Utility.focusTrapper, this.el);
    } else {
      window.app_listeners?.remove('keydown', `dialog-trap-${this.name}`);
    }
  }

  resetScroll() {
    this.el.querySelector(".dialog__wrapper")?.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }

  autoFocus() {
    setTimeout(() => {
      const autofocus = this.el.querySelector('.autofocus')
      if (autofocus) {
        if (autofocus.tagName == 'INPUT' && autofocus.value != '') {
          autofocus.select();
        } else {
          autofocus.focus();
        }
      } else {
        this.el.focus();
      }
    }, this.parent.dialogSpeed);
  }

  handleStaggeredTransitions() {
    this.content.classList.add('--stagger-transitions-inside');
    setTimeout(() => {
      this.content.classList.remove('--stagger-transitions-inside');
    }, this.parent.dialogSpeed);
  }

  activate(trigger, e = null) {
    if (this.isActive && !this.isSidelined) return
    if (trigger && document.contains(trigger)) {
      this.triggerEl = trigger;
    } else {
      this.triggerEl = e?.target || null;
    }
    this.el.classList.contains('--reset-scroll') && this.resetScroll();
    this.el.classList.remove("--is-hidden");
    this.el.setAttribute('aria-hidden', false);
    if (this.sequentialActivation) {
      setTimeout(() => {
        this.el.classList.add("--is-open");
        Client.dispatchEvent('dialog-after-open', {}, this.el);
        Utility.loadImages(this.el);
      }, this.parent.dialogSpeed + 100);
    } else {
      setTimeout(() => {
        Utility.loadImages(this.el);
      }, this.parent.dialogSpeed + 100);
    }
    this.el.setAttribute('open', true);
    this.isActive = true;
    window.app_page?.freezeScroll(!this.isAside, this.isAside);
    this.el.setAttribute('tabindex', '-1');
    this.autoFocus();
    Client.dispatchEvent('dialog-opened', this, this.el);
    this.trap();
    this.el.removeAttribute('data-sideline');
    this.el.style.setProperty('--dialog-index', '');
    this.el.classList.remove('--sidelined');
    this.isSidelined = false;
    this.onOpen(this, e);
  }

  deactivate(e = null) {
    setTimeout(() => {
      this.el.classList.remove('--hide-content', '--hide-backdrop');
      if (this.hiddenDefault && !this.isActive) {
        this.el.classList.add('--is-hidden');
      }
    }, this.parent.dialogSpeed + 100);
    if (!this.isActive) return
    setTimeout(() => {
      Client.dispatchEvent('dialog-after-close', {}, this.el);
      this.onAfterClose(this, e);
    }, this.parent.dialogSpeed)
    this.isActive = false;
    this.el.classList.remove('--is-open');
    this.el.removeAttribute('open');
    if (this.parent.activeDialogs.size == 1) {
      if (document.body.classList.contains("modal-open")) {
        document.querySelector('.page-wrapper')?.setAttribute('aria-hidden', false);
      } else {
        window.app_page?.unfreezeScroll();
      }
    }
    setTimeout(() => {
      this.trap(false);
      if (this.triggerEl && document.contains(this.triggerEl)) {
        let options = {}
        if (this.triggerEl.closest('header')) {
          options = { preventScroll: true }
        }
        if (window.app_page?.wasKeyboardEvent) {
          setTimeout(() => {
            this.triggerEl.focus(options);
            this.triggerEl = null;
          }, 200);
        }
      }
      this.el.setAttribute('aria-hidden', true);
    }, 0);
    Client.dispatchEvent('dialog-closed', this, this.el);
    this.el.removeAttribute('data-sideline');
    this.el.style.setProperty('--dialog-index', '');
    this.el.classList.remove('--sidelined');
    this.isSidelined = false;
    this.parent.activeDialogs.delete(this.name);
    this.onClose(this, e);

    // After deactivation
    if (this.parent.activeDialogs.size != 0) {
      for (const dialog of this.parent.activeDialogs.values()) {
        dialog.stepup();
      }
    } else {
      this.parent.dialogsWrapper.classList.remove('--has-active');
    }
    if (this.el.classList.contains("--single-use")) {
      this.destroy();
    }
    if (this.refreshOnClose) {
      window.location.reload();
    }
  }

  open(trigger, e = null, staggerTransitions = false) {
    if (this.isActive && !this.isSidelined) return

    let activated = false;
    this.handleScrollbar();
    if (this.parent.activeDialogs.size != 0) {
      this.parent.activeDialogs.forEach(dialog => {
        if (dialog.name == this.name) {
          dialog.activate(trigger, e);
          this.parent.dialogsWrapper.classList.add('--has-active');
          activated = true;
        } else {
          dialog.sideline();
        }
      })
    }

    if (activated) return
    staggerTransitions && this.handleStaggeredTransitions();
    if (this.hiddenDefault) {
      this.el.classList.remove("--is-hidden");
      setTimeout(() => {
        this.activate(trigger, e);
      }, 0)
    } else {
      this.activate(trigger, e);
    }
    this.parent.dialogsWrapper.classList.add('--has-active');
    this.parent.activeDialogs.set(this.name, this);
  }

  close(e = null, forced = false) {
    if (this.isSubmitting || (this.isBlocking && !forced)) return
    if (this.sequentialActivation) {
      this.el.classList.remove("--is-open");
      setTimeout(() => {
        this.deactivate(e);
      }, 0);
    } else {
      this.deactivate(e);
    }
  }

  handleScrollbar() {
    if (this.parent.activeDialogs.size == 0) {
      if (Client.getScrollbarWidth() != 0 || this.hasScrollbar) {
        if (this.isAside) {
          this.el.classList.add('--with-scrollbar')
        } else {
          this.parent.dialogsWrapper?.classList.add('--with-scrollbar')
        }
      } else {
        if (this.isAside) {
          this.el.classList.remove('--with-scrollbar')
        } else {
          this.parent.dialogsWrapper?.classList.remove('--with-scrollbar')
        }
      }
    }
  }

  sideline() {
    let index = this.el.hasAttribute('data-sideline') ? parseInt(this.el.getAttribute('data-sideline')) : 0;
    index++
    this.el.classList.add('--sidelined');
    this.el.setAttribute('data-sideline', index);
    this.el.style.setProperty('--dialog-index', 0 - index);
    this.isSidelined = true;
    this.el.setAttribute('aria-hidden', true);
    this.trap(false);
  }

  hideContent() {
    this.el.classList.add('--hide-content');
  }

  hideBackdrop() {
    this.el.classList.add('--hide-backdrop');
  }

  stepup() {
    let index = this.el.hasAttribute('data-sideline') ? parseInt(this.el.getAttribute('data-sideline')) : 1;
    index--
    this.el.classList.remove('--hide-content', '--hide-backdrop');

    if (index == 0) {
      this.el.classList.remove('--sidelined');
      this.trap();
      this.el.removeAttribute('data-sideline');
      this.el.style.setProperty('--dialog-index', '');
      this.isSidelined = false;
      this.el.setAttribute('aria-hidden', false);
    } else {
      this.el.setAttribute('data-sideline', index);
      this.el.style.setProperty('--dialog-index', 0 - index);
      this.isSidelined = true;
    }
  }

  destroy() {
    this.abortController.abort();
    window.app_listeners?.remove('keydown', `dialog-trap-${this.name}`);
    window.app_listeners?.remove('click', `${this.el.id}-dialog-click`);
    this.parent.collection.delete(this.name);
    this.isActive && this.close();
    this.el.querySelectorAll(".form").forEach(formEl => {
      formEl.getInstance("forms")?.destroy();
    });
    setTimeout(() => {
      this.el.remove();
    }, this.parent.dialogSpeed + 100);
  }

}

export default Dialog;