class ExternalValidator {
  constructor(form) {
    this.form = form;
    this.isRequired = false;
    this.valid = true;
    this.sequences = [];
    this.controller = null;
    this.sequenceMapper = {
      "recaptcha_sequence": this.recaptcha_sequence.bind(this),
      "country_state_zip": this.country_state_zip_sequence.bind(this),
      "blocked_recipient_emails": this.blocked_recipient_emails_sequence.bind(this),
    }
    this.init();
  }

  // Check the form and assign corresponding external validation sequences
  init() {
    this.controller?.abort();
    this.controller = new AbortController();
    let fields = this.form.fields;

    if (window.app_env?.recaptcha && this.form.el.hasAttribute("data-recaptcha-protect")) {
      this.sequences.push("recaptcha_sequence");
    }

    if (fields.has("country") && fields.has("zip_code") && fields.has("state")) {
      if (["US", "CA"].includes(fields.get("country").getValue()) && fields.get("country").enabled) {
        this.sequences.push("country_state_zip");
      }
    }

    if (this.form.el.id == "email-send") {
      this.sequences.push("blocked_recipient_emails");
    }

    this.isRequired = this.sequences.length != 0;
  }

  async validateAndSubmit() {
    let passed = true;
    for (const sequenceKey of this.sequences) {
      if (!this.sequenceMapper.hasOwnProperty(sequenceKey)) continue
      let sequence = this.sequenceMapper[sequenceKey];
      passed = await sequence();
      if (!passed) break
    }
    this.valid = passed;
    if (passed) {
      this.form.submit();
    } else {
      this.form.complete("external-validation-error");
    }
  }

  async recaptcha_sequence() {
    return new Promise(resolve => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(async () => {
          let action = this.form.el.getAttribute("data-recaptcha-action") || "general";
          let token = await grecaptcha.execute(window.app_env.recaptcha, { action })
          this.form.extraRequestParams["r_token"] = token;
          resolve(true);
        });
      } else {
        this.form.extraRequestParams["r_token"] = "";
        resolve(true);
      }
    })
  }

  async country_state_zip_sequence() {
    const country = this.form.fields.get("country");
    const zip = this.form.fields.get("zip_code");
    const state = this.form.fields.get("state");
    const errorMsgMap = {
      US: "Please enter a valid US zip code and state combination",
      CA: "Please enter a valid Canadian postal code and province combination"
    }
    this.form.el.addEventListener("change", (e) => {
      if (zip.errors.size != 0) {
        if ([country.el, zip.el, state.el].includes(e.target)) {
          zip.removeError("server");
          zip.rules.delete("server");
          zip.validate();
        }
      }
    }, { signal: this.controller.signal });
    try {
      const response = await fetch(
        `/validate-address?country=${country.getLabel()}&zip_code=${zip.getValue()}&state=${state.getValue()}`,
        { signal: this.form.abortController.signal });
      if (response && response.status == 200) {
        zip.removeError("server");
        zip.rules.delete("server");
        return true;
      } else {
        this.form.appendErrorsToFields({ "zip_code": errorMsgMap[country.getValue()] }, false);
        return false;
      }
    } catch (error) {
      this.form.addError(
        "server",
        "Unexpected error occurred. Please try again later"
      );
      return false;
    }
  }

  async blocked_recipient_emails_sequence() {
    let content = this.form.getFormData(true);
    if (!content.recipients) return true;
    let emails = [];
    content.recipients.forEach(recipient => {
      emails.push(recipient.email)
    })
    try {
      const response = await fetch("/check-blocksend", {
        body: JSON.stringify({ emails }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        method: "POST",
        signal: this.form.abortController.signal,
      });
      if (!response) return true
      if (response.status == 200) {
        return true;
      } else {
        let responseObj = await response.json();
        if (!responseObj.hasOwnProperty("emails")) return true
        let blockedEmails = responseObj.emails;
        let resolved = 0;
        let blockedErrors = {};
        let errorMessage = "We're sorry, some emails are undeliverable or cannot receive digital greetings."
        if (blockedEmails.length == 1) {
          errorMessage = `We're sorry, the email address ${blockedEmails[0]} is undeliverable or cannot receive digital greetings.`
        }
        this.form.fields.forEach((field) => {
          if (field.type == "email" && blockedEmails.includes(field.el.value)) {
            blockedErrors[field.truename] = "Change recipient email";
          }
        });
        this.form.appendErrorsToFields(blockedErrors, true);
        this.form.addError("server", errorMessage);
        this.form.onFieldErrorResolved = (field) => {
          if (field.type != "email" || !field.el.hasAttribute("data-id")) return
          resolved++;
          if (resolved == blockedEmails.length) {
            this.form.removeErrors();
          }
        }

        return false;
      }
    } catch (error) {
      return true;
    }
  }
}

export default ExternalValidator;