import Client from "./Client";
import Helpers from "./Helpers";
import Utility from "./Utility";

class Page {
  constructor() {
    this.gap;
    this.preventScrollPages = ["sendflow", "pdp", "mailbox"];
    this.wasKeyboardEvent = false;
    this.lastKeyPressed = null;
    this.lastClickEvent = null;
    this.env = window.app_env;
    this.scrollBarWidth = Client.scrollbar;
    this.supportsDynamicImport = Client.supportsDynamicImport();

    this.init();
  }

  init() {
    Client.scrollbar = Client.getScrollbarWidth();
    if (Client.scrollbar) {
      document.body.classList.add("--has-scrollbar");
    }
    if (window.history?.scrollRestoration) {
      if (this.preventScrollPages.includes(window.app_env.page_type)) {
        window.history.scrollRestoration = "manual";
      } else {
        window.history.scrollRestoration = "auto";
      }
    }
    document.documentElement.classList.add("--init");
    document.querySelector('.page-wrapper')?.setAttribute('aria-hidden', false);
    window.app_listeners?.add('resize', 'page-resize', this.update.bind(this));
    window.app_listeners?.add('click', 'save-last-event', e => {
      this.lastClickEvent = e;
    });
    window.addEventListener('keydown', e => {
      this.wasKeyboardEvent = true;
      this.lastKeyPressed = e.code;
    });
    window.addEventListener('mousedown', e => {
      this.lastKeyPressed = null;
      this.wasKeyboardEvent = e.detail == 0;
    });
    // this.redrawOnOrientationChange();
    // This was solving a problem with recalculating the layout gaps upon device rotation on iOS, but also was interfering with resize of the products.
    // Disabled for now, Will investigate the prior bug to be solved without redraw.
    this.addVideoLibrary();
    this.addUserIdentifier();
    this.addSafetechPixel();
    this.initRecaptcha();
    this.unpreserveScroll();
    this.watchConsent();
    this.trackPageLoad();
    this.adjustServerDates();
    this.updateApplePayElements();

    // This will force a reload on page if the state was changed in another tab.
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible") {
        const cookieCustomerNumber = Utility.getMemberNumber();
        const envCustomerNumber = window.app_env.customer?.number || 0;
        if (Number(cookieCustomerNumber) != Number(envCustomerNumber) && window.app_env.is_signed_in) {
          window.location.reload();
        }
      }
    })
  }

  updateApplePayElements() {
    const supports = Client.supportsApplePay();
    Utility.updateContent({
      applepay_action: {
        addClass: supports ? "" : "hidden",
        removeClass: supports ? "hidden" : "",
      },
      applepay_action_fallback: {
        addClass: !supports ? "" : "hidden",
        removeClass: !supports ? "hidden" : "",
      }
    })
  }

  trackPageLoad() {
    if (!this.env) return
    window.app_services?.track("page-hit", this.env);
  }

  adjustServerDates() {
    document.querySelectorAll("[data-server-date]").forEach(el => {
      let sourceDate = el.getAttribute("data-server-date", "");
      let forHumans = el.hasAttribute("data-human-date");
      let targetDate = (sourceDate && Helpers.convertDateTimeFromServer(sourceDate)) || new Date();
      let content = targetDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      if (forHumans) {
        content = Helpers.convertDateForHumans(
          targetDate,
          el.hasAttribute("data-full"),
          el.hasAttribute("data-prefixed")
        )
      }
      el.setAttribute("data-client-date", targetDate.toISOString());
      el.textContent = content;
    })
  }

  watchConsent() {
    if (!Client.getConsent()) {
      document.querySelector("#accept_cookies")?.addEventListener("click", () => {
        document.dispatchEvent(new Event("cookie_consent"))
      })
    }
  }

  addUserIdentifier() {
    Utility.loadResources('AGcookieLibrary',
      [`${window.app_env.imghost}/js/cookie_util.js`], () => {
        if (window._uxa) {
          if (AGCookie.getCookieValue('customer', 'email') !== null) {
            window._uxa.push(['trackPageEvent', '@user-identifier@' + AGCookie.getCookieValue('customer', 'email')]);
          }
        }
      });
  }

  addSafetechPixel() {
    if (!["payment", "pof"].includes(app_env?.page_type)) return;
    if (!app_env?.safetech_url) return
    Utility.loadResources('safetech', [`${app_env.safetech_url}${app_env.safetech_membership}&s=${app_env.safetech_session_id}`], () => {
      const client = new ka.ClientSDK();
      client.autoLoadEvents();
    });
  }

  addVideoLibrary() {
    const video_section = document.querySelector(".video-section");
    if (!video_section) return
    Utility.loadResources('brightcove',
      ['//players.brightcove.net/1640544087001/ByzTFcDCe_default/index.min.js'],
      () => { });
  }

  redrawOnOrientationChange() {
    if (!Client.isAppleHandheldDevice()) return
    window.addEventListener("orientationchange", () => {
      const wrapper = document.querySelector(".page-wrapper");
      if (!wrapper) return
      document.body.style.height = wrapper.offsetHeight + 'px';
      wrapper.classList.add("hidden");
      setTimeout(() => {
        wrapper.classList.remove("hidden");
        document.body.style.height = "";
      }, 50);
    });
  }

  update(client = null) {
    const height = client.height || Client.height;
    const width = client.width || Client.width;
    document.body.style.setProperty('--scrollbar-width', Client.scrollbar + 'px');
    document.body.style.setProperty('--client-height', height + 'px');
    document.body.style.setProperty('--client-width', width + 'px');
  }

  freezeScroll(blocking = true, isAside = false) {
    if (document.body.classList.contains('--scroll-freeze')) {
      if (blocking) {
        document.querySelector('.page-wrapper')?.setAttribute('aria-hidden', true);
      }
      return
    }
    if (!this.gap) {
      this.gap = Client.scrollbar;
    }
    if (blocking) {
      document.querySelector('.page-wrapper')?.setAttribute('aria-hidden', true);
    }
    document.body.classList.add('--scroll-freeze');
    document.body.style.setProperty('--scrollbar-gap', this.gap + 'px');
    if (isAside) {
      document.querySelectorAll(".main-content-wrapper, .footer-wrapper, .header-wrapper").forEach(el => {
        el.setAttribute("aria-hidden", true);
      })
    }
  }

  unfreezeScroll() {
    if (!document.body.classList.contains('--scroll-freeze')) {
      document.querySelector('.page-wrapper')?.setAttribute('aria-hidden', false);
      return
    }
    if (this.gap) {
      this.gap = null;
    }
    document.querySelector('.page-wrapper')?.setAttribute('aria-hidden', false);
    document.body.classList.remove('--scroll-freeze');
    document.body.style.setProperty('--scrollbar-gap', '');
    document.querySelectorAll(`.main-content-wrapper[aria-hidden="true"],
      .footer-wrapper[aria-hidden="true"],
      .header-wrapper[aria-hidden="true"]`).forEach(el => {
      el.setAttribute("aria-hidden", false);
    })
  }

  initRecaptcha() {
    if (!window.app_env?.recaptcha) return
    Utility.elementRendered('.grecaptcha-badge').then(el => {
      el.parentElement.classList.add('grecaptcha-page-container');
    })
  }

  showRecaptcha() {
    let recaptchaEl = document.querySelector('.grecaptcha-page-container');
    if (!recaptchaEl) return
    recaptchaEl.classList.add('--activating');
    setTimeout(() => {
      recaptchaEl.classList.remove('--activating');
      if (recaptchaEl.classList.contains("--deactivating")) return
      recaptchaEl.classList.add('--active');
    }, 10)
  }

  hideRecaptcha() {
    let recaptchaEl = document.querySelector('.grecaptcha-page-container.--active');
    if (!recaptchaEl) return
    recaptchaEl.classList.add('--deactivating');
    setTimeout(() => {
      recaptchaEl.classList.remove('--deactivating');
      if (recaptchaEl.classList.contains("--activating")) return
      recaptchaEl.classList.remove('--active');
    }, 10)
  }

  unpreserveScroll() {
    if (app_env.page_type == 'pof') {
      window.history.scrollRestoration = "manual";
    }
  }
}

export default Page;