class FrontendValidator {
  constructor(form) {
    this.form = form;
    this.valid = true;
    this.sequences = [];
    this.forwardedErrors = new Map();
    this.sequenceMapper = {
      "base_sequence": this.base_sequence.bind(this),
      "duplicated_emails_sequence": this.duplicated_emails_sequence.bind(this),
      "generate_message_sequence": this.generate_message_sequence.bind(this),
    };
    this.init();
  }

  init() {

    this.sequences.push("base_sequence")

    if (this.form.el.classList.contains("generate-message")) {
      this.sequences.push("generate_message_sequence");
    }

    if (this.form.id == "email-send") {
      this.sequences.push("duplicated_emails_sequence")
    }
  }

  validate() {
    this.forwardedErrors = new Map();

    this.valid = true;
    for (const sequenceKey of this.sequences) {
      if (!this.sequenceMapper.hasOwnProperty(sequenceKey)) continue
      let sequence = this.sequenceMapper[sequenceKey];
      this.valid = sequence();
      if (!this.valid) {
        break;
      }
    }

    return this.valid
  }

  base_sequence() {
    // Base validation sequence
    let valid = true
    this.form.fields.forEach((field) => {
      if (!field.validate("submit")) {
        valid = false;
      }
    });
    return valid;
  }

  generate_message_sequence() {
    let activity = this.form.fields.get("favorite_activity");
    let occasion = this.form.fields.get("occasion");
    let infoBar = this.form.el.querySelector("#ai-message-instructions");
    if (!activity || !occasion) return false
    let fields = [activity, occasion];
    const rules = [
      {
        key: "required",
        test: () => activity?.getValue() || occasion?.getValue(),
        errorMessage: "Please enter an activity or occasion"
      }
    ];

    const updateField = (field, key, isError) => {
      field.field.classList.toggle("--error", isError);
      field.el.setAttribute("aria-invalid", isError);
      if (isError) {
        infoBar && field.removeDescription(infoBar.id);
        field.addDescription(key);
      } else {
        field.removeDescription(key);
        infoBar && field.addDescription(infoBar.id);
      }
    }

    /**
     * Iterates through a list of rules and checks if each rule passes the test.
     * If a rule fails the test, it adds an error to the form, marks the corresponding
     * field as an error, and sets up event listeners to remove the error when the field
     * is corrected.
     */
    for (let rule of rules) {
      if (!rule.test()) {
        if (!fields.includes(document.activeElement)) {
          this.form.outputEl.setAttribute("aria-hidden", true);
          setTimeout(() => {
            this.form.outputEl.removeAttribute("aria-hidden");
          }, 600);
        };
        infoBar?.classList.add("--empty");
        this.forwardedErrors.set("server", rule.errorMessage);
        fields.forEach(field => {
          updateField(field, `${this.form.id}-server-error`, true);
          field.onInput = () => {
            if (!field.getValue()) return
            this.form.removeError("server");
            infoBar?.classList.remove("--empty");
            this.form.renderErrors();
            fields.forEach(innerField => {
              updateField(innerField, `${this.form.id}-server-error`, false);
              innerField.onInput = () => { };
            });
          }
        });
        return false;
      }
    }

    return true
  }

  duplicated_emails_sequence() {
    // Validator sequence for duplicated emails on sendflow
    let valid = true;
    let duplicateErrors = {};
    let emailList = [];
    let duplicateErrorMessage = "This email is duplicated";
    this.form.fields.forEach((field) => {
      if (
        field.el.value != "" &&
        field.el.hasAttribute("data-key") &&
        field.el.getAttribute("data-key") == "email"
      ) {
        if (emailList.includes(field.el.value)) {
          valid = false;
          duplicateErrors[field.truename] = duplicateErrorMessage;
        } else {
          emailList.push(field.el.value);
        }
      }
    });

    this.form.appendErrorsToFields(duplicateErrors, true);
    return valid;
  }

}

export default FrontendValidator;
