import Client from "../../base/Client";
import Utility from "../../base/Utility";

class SSOSession {
  constructor(provider, form, event) {
    this.provider = provider;
    this.form = form;
    this.event = event;
    this.trigger = event.target;
    this.token = null;
    this.sso_extra = null;
    this.flow = "signin";
    this.locked = false;
    this.init();
  }

  init() {
    if (this.provider) {
      this.provider.start(this.event);
    } else {
      console.warn("Provider Not Enabled");
    }
  }

  async getUserData(sso_provider, sso_token, sso_extra) {
    try {
      const response = await fetch(app_env.shost + "/validate-sso-token", {
        method: "POST",
        body: Utility.objectToFormData({sso_token, sso_provider, sso_extra}),
      });
      const json = await response.json();
      if(json.status === "error") throw new Error("fetch-failed");
      return json.data || json;
    } catch(error) {
      console.warn(error);
      return false
    }
  }

  getEndpoint() {
    const endpoints = {
      "authenticate": "/myaccount/authenticate",
      "register": "/create-account",
      "signin": "/signin"
    };
    return endpoints.hasOwnProperty(this.flow) ? endpoints[this.flow] : false
  }

  async handleAuthSuccess(sso_provider, sso_token, sso_extra="") {
    this.trigger.classList.add("--loading");
    this.token = sso_token;
    this.sso_extra = sso_extra;
    this.locked = true;
    let user = await this.getUserData(sso_provider, sso_token, sso_extra);
    this.locked = false;
    if (!user) return this.fail();
    if (this.form.id == "verify-form") {
      this.flow = "authenticate";
    } else if (!user.exists) {
      this.flow = "register";
    } else {
      this.flow = "signin";
    }

    this.form.onAfterSuccess = this.handleFormSuccess.bind(this);
    this.form.customSubmitter = this.trigger;
    this.form.customEndpoint = this.getEndpoint();
    this.form.customPayload = this.getPayload(user);
    this.form.silentSubmit();
  }

  handleFormSuccess() {
    let customer = this.form.lastRequest?.response?.data?.customer;
    if (!customer) return true
    let requestObj = this.form.lastRequest?.getRequestObject() || {};
    if (!requestObj?.sso_token) return true
    if (["REG", "RFU"].includes(customer.status) && this.flow == "register") {
      if (!["join"].includes(window.app_env.page_type)) {
        window.app_storage?.set("ag-affiliate-welcome", "true", "session");
      }
    }
    return true
  }

  getPayload(user) {
    let formContent = this.form.getFormData(true);
    let extraFields = ["referer", "app", "sti", "ti", "product_number", "usage_id", "autologin"];
    let payload = {
      "email": user.email,
      "sso_token": this.token,
      "sso_provider": this.provider.label,
      "password": "",
      "csrfmiddlewaretoken": Client.getCSRFToken(),
      "missing_sso_name": (user.last_name) ? false : true,
    };
    if (this.flow == "register") {
      payload["first_name"] = user.first_name;
      payload["last_name"] = user.last_name;
      payload["account_id"] = user.account_id;
    }

    extraFields.forEach(key => {
      if (formContent.hasOwnProperty(key)) {
        payload[key] = formContent[key];
      }
    })

    return {
      method: "POST",
      body: Utility.objectToFormData(payload)
    }
  }

  fail(message = "") {
    this.form.addError("server", message || `Could not connect to your ${this.provider.label} account.`)
    this.form.renderErrors();
    this.form.removeLoading();
    this.end();
  }

  end() {
    this.trigger.classList.remove("--loading");
  }
}

export default SSOSession