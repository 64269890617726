import Utility from "../base/Utility";

class Tracker {
  constructor(services) {
    this.services = services;
    this.dy = this.services.dy;
    this.ga = this.services.ga;
    this.constructor = this.services.constructor;
    this.events = new Map();
    this.init();
  }

  track(event, content = null) {
    this.events.get(event)?.(content);
    document.dispatchEvent(
      new CustomEvent(`app-${event}`, {
        cancelable: false,
        detail: content,
      })
    );
  }

  init() {
    // Sendflow Events
    this.events.set("send-started", this.sendStarted.bind(this));
    this.events.set("send-gift-attached", this.sendGiftAttached.bind(this));
    this.events.set("send-completed", this.sendCompleted.bind(this));
    // Contacts & Reminders Events
    this.events.set("contact-added", this.contactAdded.bind(this));
    this.events.set("contact-updated", this.contactUpdated.bind(this));
    this.events.set("contact-removed", this.contactRemoved.bind(this));
    // Customer Events
    this.events.set("customer-signed-in", this.customerSignedIn.bind(this));
    this.events.set("customer-registered", this.customerRegistered.bind(this));
    this.events.set("customer-autorenew-changed", this.customerAutorenewChanged.bind(this));
    this.events.set("customer-membership-started", this.customerMembershipStarted.bind(this));
    // Join Events
    this.events.set("join-started", this.joinStarted.bind(this));
    this.events.set("join-promo-code-used", this.joinPromoCodeUsed.bind(this));
    this.events.set("join-completed", this.joinCompleted.bind(this));
    // Product Events
    this.events.set("product-listing-filtrated", this.productListingFiltrated.bind(this));
    this.events.set("product-quickview-opened", this.productQuickviewOpened.bind(this));
    // Preferences Events
    this.events.set("newsletter-subscription", this.newsletterSubscription.bind(this));
    // Page Events
    this.events.set("page-hit", this.pageHit.bind(this));
    this.events.set("form-successful-submit", this.formSuccessfulSubmit.bind(this));

    // Add to this list all required app events and also add corresponding method to handle that event
    // Within application you can trigger this event by calling window.app_services?.track(event, content)
    // where event is the name of the event and content is the required context.
  }

  pageHit(env) {
    // Any events related to page load
    if ("search" == env.page_type) {
      this.dy.track("search_pageview");
    } else if ("confirm-join" == env.page_type && env.subscription_order) {
      this.processJoinConfirm(env);
    }
  }

  processJoinConfirm(env) {
    let order = env.subscription_order
    let url = new URL(window.location.href);
    if (!url.searchParams.has("track")) return
    url.searchParams.delete('track');
    window.history.replaceState({}, document.title, url);
    if (env.customer.previous_status == "AFU") {
      this.track("customer-registered", env.customer.email);
      this.track("customer-signed-in", env.customer.email);
      if (env.subscribed_to_newsletter) {
        this.track("newsletter-subscription", env.customer.email);
      }
    }
    if (order.promo_code_used) {
      this.track("join-promo-code-used", order.promo_code_used);
    }
    this.track("join-completed", order);
  }

  formSuccessfulSubmit(form) {
    if (["payment", "pof"].includes(window.app_env?.page_type)) return
    let trackedForms = [
      "newsletter-form",
      "email-preferences",
      "register-form",
      "join-form",
      "sign-in",
      "verify-form",
      "manage-membership",
      "delivery-date-unlock",
      "ai-message-unlock",
    ];
    if (!form || !trackedForms.includes(form.id)) return

    let endpoint = form.lastRequest?.endpoint;
    let name = form.id;
    let requestObj = form.lastRequest?.getRequestObject() || {};
    let responseObj = form.lastRequest?.response || {};

    // Newsletter Subscription
    let email = requestObj?.email || requestObj?.newsletter_email || window.app_env?.customer?.email;
    if (name == "email-preferences" && requestObj?.pqsn && requestObj?.pqsn == "5030") {
      email && this.track("newsletter-subscription", email);
    } else if (["register-form", "newsletter-form"].includes(name) && requestObj?.pqsn5030) {
      email && this.track("newsletter-subscription", email);
    }

    // Signin & Register RFU
    if (["join-form", "sign-in", "register-form"].includes(name)) {
      if (endpoint.includes("/signin")) {
        email && this.track('customer-signed-in', email);
      } else if (endpoint.includes("/create-account")) {
        email && this.track('customer-registered', email);
        email && this.track('customer-signed-in', email);
      }
    }

    // Started Join
    if (["verify-form", "sign-in", "join-form"].includes(name)) {
      if (responseObj?.data?.redirect_url && responseObj.data.redirect_url.includes("/join/payment")) {
        this.track('join-started');
      }
    }

    // Changed AutoRenew
    if ("manage-membership" == name && ["N", "Y"].includes(form.content.auto_renew[0])) {
      let enabled = form.content.auto_renew[0] == 'Y';
      this.track("customer-autorenew-changed", { enabled });
    }

    if (["delivery-date-unlock", "ai-message-unlock"].includes(name)) {
      window.app_env.customer.is_in_trial = false;
      this.track("customer-membership-started", { source: name });
    }
  }

  sendStarted(order) {
    if (order.mode == "giftcard-first") return
    this.dy.track({
      name: "Add to Cart",
      properties: {
        productId: `${order.product.product_number}`,
        dyType: "add-to-cart-v1",
        currency: "USD",
        value: 1,
        quantity: 1,
        cart: [
          {
            productId: `${order.product.product_number}`,
            quantity: 1,
            itemPrice: 1,
          },
        ],
      },
    });
    let free = ["MCP"].includes(window.app_env?.customer?.status || "") ? "" : "_free";
    if (order.product.model == "creatacard") {
      this.dy.track(`cart_cac${free}`);
    } else {
      this.dy.track(`cart_ecard${free}`);
    }
  }

  sendGiftAttached(order) {
    if (order && order.mode == "giftcard-first") return
    this.dy.track("purchase_gift_card_ecard_add_on");
  }

  sendCompleted(order) {
    if (order.mode == "giftcard-first") return
    this.constructor.track("purchase", {
      items: [
        {
          item_id: String(order.product.product_number),
          quantity: order.recipients.length,
        },
      ],
      order_id: String(order.id),
    })
    this.dy.track({
      name: "Purchase",
      properties: {
        uniqueTransactionId: String(order.id),
        dyType: "purchase-v1",
        value: 1,
        currency: "USD",
        cart: [{
          productId: `${order.product.product_number}`,
          quantity: 1,
          itemPrice: 1,
        }],
      },
    });

    let free = ["MCP"].includes(window.app_env?.customer?.status || "") ? "" : "_free";
    if (order.product.model == "creatacard") {
      this.dy.track(`purchase_cac${free}`);
    } else {
      this.dy.track(`purchase_ecard${free}`);
    }

    this.ga.track({
      name: "Ecard Send Email",
      category: `Send with ${order.gift ? "Digital Gift" : "No Gift"}`,
      detail: order.gift?.sku || "N/A",
      extra: {'nonInteraction': true}
    })
  }

  contactUpdated(changes = []) {
    if (changes.includes("added-anniversary-reminder") && changes.includes("added-birthday-reminder")) {
      this.dy.track("Birthday_Anniversary_Reminder_pageview");
    } else if (changes.includes("added-anniversary-reminder")) {
      this.dy.track("Anniversary_Reminder_pageview");
    } else if (changes.includes("added-birthday-reminder")) {
      this.dy.track("Birthday_Reminder_pageview");
    }
  }

  contactAdded(changes = []) {
    this.contactUpdated(changes);
  }

  contactRemoved() { }

  customerSignedIn(email) {
    this.dy.track({
      name: "Login",
      properties: {
        dyType: "login-v1",
        hashedEmail: email.toLowerCase(),
      }
    })
  }

  customerRegistered(email) {
    this.dy.track({
      name: "Signup",
      properties: {
        dyType: "signup-v1",
        hashedEmail: email.toLowerCase(),
      }
    })
  }

  customerAutorenewChanged(autorenew) {
    this.dy.track(`autorenew_${autorenew.enabled ? "yes" : "no"}`);
  }

  customerMembershipStarted(content) {
    // handler for free trial users who started early their memebership
  }

  joinStarted(offer) {
    // this.dy.track({
    //   name: "Add to Cart",
    //   properties: {
    //     productId: `${offer.merchnum}`,
    //     dyType: "add-to-cart-v1",
    //     currency: "USD",
    //     value: offer.price,
    //     quantity: 1,
    //     cart: [
    //       {
    //         productId: `${offer.merchnum}`,
    //         quantity: 1,
    //         itemPrice: offer.price,
    //       },
    //     ],
    //   },
    // });
    this.dy.track("cart_subscription");
  }

  joinPromoCodeUsed(code) {
    this.dy.track({
      name: "Promo Code Entered",
      properties: {
        dyType: "enter-promo-code-v1",
        code: String(code)
      }
    });
  }

  joinCompleted(subOrder) {
    this.dy.track({
      name: "Purchase",
      properties: {
        uniqueTransactionId: String(subOrder.confirmation_number),
        dyType: "purchase-v1",
        value: Number(subOrder.total_price),
        currency: "USD",
        cart: [
          {
            productId: `${subOrder.merch_number}`,
            quantity: 1,
            itemPrice: Number(subOrder.total_price)
          }
        ]
      }
    });
    this.dy.track("purchase_subscription");
  }

  productListingFiltrated(response) { }

  productQuickviewOpened(product) { }

  newsletterSubscription(email = null) {
    if (!email) return console.log("no email");
    this.dy.track({
      name: "Newsletter Subscription",
      properties: {
        dyType: "newsletter-subscription-v1",
        hashedEmail: email.toLowerCase()
      }
    })
  }
}

export default Tracker