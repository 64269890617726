import Client from "../base/Client";
import Helpers from "../base/Helpers";
import Render from "../base/Render";
import Utility from "../base/Utility";

class Reminders {
  constructor() {
    this.itemTemplate = document.querySelector(".reminder-list-item-template") || null;
    this.collection = new Map();
    this.init();
  }

  init() {
    this.hydrateDate();
    this.initReminders();
  }

  hydrateDate() {
    const reminderCurrentDate = document.querySelector("#reminders__current-date");
    if (!reminderCurrentDate) return;

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('en-US', { month: 'long' });

    reminderCurrentDate.innerHTML = currentMonth + " " + currentDate.getDate() + Utility.getOrdinal(currentDate.getDate());
  }

  initReminders() {
    document.querySelectorAll(".reminders")?.forEach(el => {
      if (el.id == '') {
        el.id = `reminders-instance`;
      }
      Utility.fixDuplicates(el);
      this.collection.set(el.id, new RemindersInstance(el, this));
    })

  }
}

class RemindersInstance {
  constructor(el, parent) {
    this.el = el;
    this.parent = parent;
    this.hydrated = false;
    this.dialog = null;
    this.data = null;
    this.storageKey = `ag-${window.app_env?.box_env || "general"}-reminders`;
    this.storageType = "local";
    this.totalEmpty = 0;
    this.totalReminders = 0;
    this.today = new Date().toJSON().split('T')[0];
    this.customerNumber = window.app_env?.customer?.number || 0;
    this.init();
  }

  init() {
    document.addEventListener('app-contact-updated', this.handleContactUpdate.bind(this));
    document.addEventListener('app-contact-removed', this.handleContactUpdate.bind(this));
    document.addEventListener('app-contact-added', this.handleContactUpdate.bind(this));
    !window.app_env.is_signed_in && window.app_storage.remove(this.storageKey, this.storageType);
    const recovered = window.app_storage.get(this.storageKey, this.storageType);
    if (recovered) {
      if (this.today != recovered.date || this.customerNumber != recovered.customer)
      window.app_storage.remove(this.storageKey, this.storageType)
    }
    const dialogEl = this.el.closest(".dialog");
    if (dialogEl) {
      this.dialog = window.app_dialogs?.collection.get(dialogEl.id);
    }

    if (this.dialog) {
      this.dialog.el.addEventListener("dialog-opened", this.hydrateReminders.bind(this))
      if (window.app_storage.get(this.storageKey, this.storageType)) {
        this.hydrateReminders();
      }
    } else {
      this.hydrateReminders();
    }

    // Handle resets
    let reminderForm = window.app_forms?.collection.get("confirm-reminders");
    if (reminderForm) {
      reminderForm.onSuccess = () => {
        let entry = JSON.parse(reminderForm.lastRequest?.package?.body || "{}");
        if (entry.events) {
          let changes = Helpers.getEventsChanges(entry.events, null);
          changes && window.app_services?.track("contact-updated", changes);
        }
        return true;
      }
    }
  }

  handleContactUpdate(e) {
    let changes = e?.detail || [];
    let invalidate = false;
    changes?.forEach?.(change => {
      if (!change.includes("reminder") && (change.includes("anniversary") || change.includes("birthday"))) {
        invalidate = true;
      } else if (change.includes("fname") || change.includes("lname")) {
        invalidate = true;
      }
    })
    if (invalidate || e.type == "app-contact-removed") {
      this.hydrated = false;
      window.app_storage.remove(this.storageKey, this.storageType);
    }
  }

  async hydrateReminders() {
    if (this.hydrated || !window.app_env.is_signed_in) return
    this.totalEmpty = 0;
    this.totalReminders = 0;
    this.setLoading();
    this.setEmpty("birthday", false);
    this.setEmpty("anniversary", false);
    this.sessionData = window.app_storage.get(this.storageKey, this.storageType);
    if (this.sessionData) {
      this.data = this.sessionData;
    } else {
      this.data = await this.fetchReminders();
      this.data["date"] = this.today;
      this.data["customer"] = this.customerNumber;
      window.app_storage.set(this.storageKey, this.data, this.storageType);
    }
    this.hydrateReminderSection("birthday");
    this.hydrateReminderSection("anniversary");
    this.setLoading(false);
    this.manageHolidays();
    this.hydrated = true;
  }

  manageHolidays() {
    let holidayItems = this.el.querySelectorAll(`[data-content-key="reminders-holiday-list"] > li`);
    let originalSize = holidayItems.length;
    let visibleCount = originalSize > this.totalReminders ? originalSize - this.totalReminders : 2;
    holidayItems.forEach((item, counter) => {
      if (counter >= visibleCount) {
        item.classList.add("hidden");
      } else {
        item.classList.remove("hidden");
      }
    })
  }

  async fetchReminders() {
    try {
      let data = '';
      let endpoint = "/myaccount/reminders";
      let options = {
        method: "POST",
        headers: {'X-Csrftoken': Client.getCSRFToken()}
      };
      let response = await fetch(endpoint, options);
      if (response.status == 200) {
        data = await response.json();
        return data;
      } else {
        return {};
      }
    } catch (e) {
      return {};
    }
  }

  hydrateReminderSection(key) {
    const hasEntries = this.data && this.data.hasOwnProperty(`${key}_list`) && this.data[`${key}_list`].length != 0;
    if (hasEntries) {
      this.totalReminders = this.totalReminders + this.data[`${key}_list`].length;
    }
    let entries = hasEntries ? this.data[`${key}_list`] : [
      {month:"",day:"",link:{href:"",title:""}},
      {month:"",day:"",link:{href:"",title:""}}
    ];
    let inner = "";
    entries.forEach(entry => {
      inner += Render.interpolateTemplate(this.parent.itemTemplate, {
        href: entry.link.href ? `href="${entry.link.href}"` : "",
        date: `${entry.month} ${entry.day}`,
        title: entry.link.title
      }, true)
    })

    let update = {};
    update[`reminders-${key}-list`] = {inner};
    Utility.updateContent(update, this.el);
    !hasEntries && this.setEmpty(key);
  }

  setEmpty(type="birthday", isEmpty = true) {
    let update = {};
    update[`reminders-${type}-block`] = {
      addClass: isEmpty ? "hidden" : "",
      removeClass: isEmpty ? "" : "hidden",
    }
    if (!isEmpty) {
      update[`reminders-empty-block`] = {
        addClass: "hidden"
      }
    } else {
      this.totalEmpty++;
      if (this.totalEmpty == 2) {
        update[`reminders-empty-block`] = {
          removeClass: "hidden"
        }
      }
    }
    Utility.updateContent(update, this.el)
  }

  setLoading(isLoading = true) {
    let update = {};
    ["birthday", "anniversary"].forEach(key => {
      update[`reminders-${key}-list`] = {
        addClass: isLoading ? "--loading" : "",
        removeClass: isLoading ? "" : "--loading",
      }
    });
    Utility.updateContent(update, this.el);
  }

}

export default Reminders;


