import Client from "../../../base/Client";
import Utility from "../../../base/Utility";
import BaseProduct from "./BaseProduct";

class InteractiveProduct extends BaseProduct {
  constructor(el, parent, type, env=null) {
    super(el, parent, type, env);
    this.initInteractive()
  }

  initInteractive() {
    Utility.updateContent({
      "send-submit": {
        removeClass: "form-interact",
      }
    }, this.el)
    this.el.classList.add('--waits-visible');
    this.loadProduct();
    if (this.product.has_photo_modal) {
      Utility.loadResources("photo-edit-modal", [`https://ak.imgag.com/v2/dpd/agi-expressions/release/2.2.0/assets/idex.css`], () => {}, false);
    }
  };

  afterLoad() {
    Utility.elementRendered('#agi-expression-container', this.container).then((el) => {
      Utility.elementRendered('img, canvas', this.container).then((el) => {
        if (el.tagName == 'CANVAS' && Client.isHidden(el)) {
          Utility.elementRendered('img', this.container).then((el) => {
            Utility.waitVisibleLoad(this.container, this.ready.bind(this));
          })
        } else {
          this.ready();
        }
      })
    })
  }

  onReady() {
    if (this.container.offsetWidth > this.container.offsetHeight) {
      this.el.classList.add("--landscape-interactive")
    }
  }

  initListeners() {
    this.productInterface.addEventListener(agi.ProductEvent.READY_TO_SEND, (e) => {
      setTimeout(() => {
        let pers = this.getPersonalizations(e);
        this.setFormPersonalizations(pers);
        this.showSendButton();
      }, 100);
    });
    this.productInterface.addEventListener(agi.ProductEvent.SEND_BUTTON_CLICKED, (e) => {
      let pers = this.getPersonalizations(e);
      this.setFormPersonalizations(pers);
      this.form?.el.submit();
    });
  }

  triggerSend(e) {
    if (this.isSending) return e?.preventDefault?.();
    this.isSending = true;
    e?.preventDefault?.();
    let pers = this.getPersonalizations(e);
    this.setFormPersonalizations(pers);
    this.form?.el.submit();
  }

}

export default InteractiveProduct;