import Utility from "../base/Utility";
import Table from "./Table";

class Tables {
  constructor() {
    this.collection = new Map();
    this.sources = new Map();
    this.init();
  }

  addSource(key, data, update = false) {
    if (!key || key == '' || !data) return
    if (!this.sources.has(key) || update) {
      this.sources.set(key, data instanceof Array ? [...data] : { ...data });
    }
  }

  getSource(key) {
    return this.sources.has(key) ? this.sources.get(key) : null;
  }

  init() {
    const els = document.querySelectorAll('.table:not(.--no-js, .--ignore)');
    if (!els || els.length == 0) return
    els.forEach(this.add.bind(this));
  }

  add(el, reinit = false) {
    if (el.id == '') {
      el.id = `table-instance`;
    }
    Utility.fixDuplicates(el);
    if (this.collection.has(el.id)) {
      if (reinit) {
        this.collection.get(el.id).destroy()
      } else {
        return
      }
    }
    this.collection.set(el.id, new Table(el, this));
  }

}

export default Tables;