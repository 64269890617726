import Client from "../../../base/Client";
import Render from "../../../base/Render";
import Utility from "../../../base/Utility";
import BaseField from "./BaseField";

class SelectField extends BaseField {
  constructor(el, form) {
    super(el, form);
    this.type = 'select';
    this.typeBuffer = '';
    this.listbox = null;
    this.listboxList = null;
    this.listboxScroll = null;
    this.listboxContainer = null;
    this.isListboxOpen = false;
    this.selectOnFocus = false;
    this.hasFullQueryMatch = false;
    this.lastValue = el.value;
    this.value = el.value;
    this.label = el.value;
    this.optionsData = null;
    this.innerTextTemplate = el.getAttribute("data-inner-text-template");
    this.forceValue = !el.hasAttribute('data-suggestions');
    this.maxOptionsVisible = Number(el.getAttribute('data-options-count')) || 7;
    this.scrollOffset = Number(el.getAttribute('data-scroll-offset')) || 4;
    this.autocompleteValue = el.getAttribute('autocomplete') || 'off';
    this.printablesTrigger = !el.hasAttribute("data-no-printables");
    this.queriedTerm = el.value.trim();
    this.lastQueriedTerm = null;
    this.announcerTimeout = null;
    this.clone = null;
    this.templates = {
      listbox: Render.getTemplateString(this.field, "listbox"),
      empty: Render.getTemplateString(this.field, "listbox-empty"),
      item: Render.getTemplateString(this.field, "listbox-item"),
      separator: Render.getTemplateString(this.field, "listbox-separator"),
    };

    // Hooks
    this.onFocus = (label, value) => { };
    this.onSelect = (label, value) => { };

    // Filters
    this.optionsLoadFilter = (options) => (options);
    this.optionsSearchFilter = (options, term) => (options);
    this.optionTemplateMapFilter = (map) => (map);

    // Initialization
    this.initSelect();
  }

  get requiresClone() {
    return !this.isAutocomplete || !["off", "none"].includes(this.autocompleteValue)
  }

  get isEditabe() {
    return !this.el.hasAttribute('readonly') && !this.field.classList.contains("--select-only")
  }

  get isAutocomplete() {
    return this.el.getAttribute('data-type') == 'predictive-search'
  }

  get isSubmitOnSelect() {
    return this.el.hasAttribute('data-submit-on-select')
  }

  get searchFor() {
    return this.el.getAttribute('data-search-for')
  }

  optionTemplateMap(map) {
    return this.optionTemplateMapFilter(map)
  }

  initClone() {
    const clone = document.createElement("select");
    clone.id = this.el.id + "-clone";
    clone.name = this.key + "-clone";
    clone.setAttribute("autocomplete", this.autocompleteValue);
    this.el.name = this.el.name + "-clone";
    clone.classList.add("clone", "--ignore");
    this.el.parentElement.appendChild(clone);
    this.clone = this.field.querySelector(".clone");
    this.clone.addEventListener("change", () => {
      this.setValue(this.clone.options[this.clone.selectedIndex].value, true);
    }, { signal: this.abortController.signal })
  }

  initSelect() {
    // Init
    this.el.type = this.isEditabe ? "text" : "button";
    if (!this.isEditabe) {
      this.el.setAttribute("aria-description", "");
      this.field.classList.add("--select-only");
      this.el.removeAttribute("readonly");
      this.handlePlaceholder();
    } else if (!this.isAutocomplete) {
      this.hasPreventAutofill = true;
      this.preventAutofill();
    }
    this.el.setAttribute("role", "combobox");
    this.el.setAttribute("aria-haspopup", "listbox");
    this.el.setAttribute("aria-expanded", "false");
    this.el.setAttribute("aria-autocomplete", this.isAutocomplete ? "both" : "list");
    this.el.setAttribute('aria-controls', `${this.el.id}-listbox`);
    this.requiresClone && this.initClone();
    // Get Data
    this.getOptions();
    // Rules
    this.initRules();
    if (this.forceValue) {
      this.rules.set('pattern', {
        error: this.el.getAttribute('data-pattern-error') || `Select ${this.nameLowercase} from list`,
        formError: this.el.getAttribute('data-pattern-form-error') || `Choose ${this.nameLowercase} from list`,
        test: v => (v.trim() == '' || !this.optionsFlattened || Object.keys(this.optionsFlattened)?.includes(this.getLabel())),
      });
    }
    // Listeners
    window.app_listeners?.add('focus', `field-listbox-${this.el.id}`, this.focusHandler.bind(this));
    window.app_listeners?.add('click', `field-listbox-${this.el.id}`, this.clickHandler.bind(this));
    this.el.addEventListener('input', this.inputHandler.bind(this), { signal: this.abortController.signal });
    this.el.addEventListener('keydown', this.keydownHandler.bind(this), { signal: this.abortController.signal });
    this.el.addEventListener("soft-select", () => {
      clearTimeout(this.announcerTimeout);
      this.announcerTimeout = setTimeout(() => {
        window.app_accessibility?.announce({ message: `selected ${this.el.value} for ${this.nameLowercase}` })
      }, 1000)
    }, { signal: this.abortController.signal });
  }

  focusHandler(e) {
    if (e.target == this.el && this.hasPreventAutofill) {
      this.preventAutofill();
    }
    if (e.target != document.body && !e.composedPath().includes(this.field)) {
      if (!this.isListboxOpen) return
      this.closeListbox(false, "focus-outside");
    } else if (e.target != this.el && !e.target.classList.contains("field__button")) {
      if (!this.isListboxOpen) return
      e.preventDefault();
      this.el.focus();
    }
  }

  /**
   * Retrieves options data based on the attributes of the element. Will also
   * be triggered if 'data-ready' or 'data-get' will be modified in DOM
   * - If the element has 'data-ready' attribute:
   *   - If the attribute value starts with 'range', it generates a range of numbers and calls loadOptions method.
   *   - If the attribute value starts with 'env', it retrieves data from window.app_env and calls loadOptions method.
   *   - If the attribute value starts with 'list', it creates an object from the list and calls loadOptions method.
   *   - If the attribute value starts with 'common', it creates a common option structure (as months) and calls loadOptions method.
   *   - If the attribute value is a JSON string, it parses the string and calls loadOptions method.
   * - If the element has 'data-get' attribute, it fetches data from the specified path and calls loadOptions method.
   */
  getOptions() {
    if (this.el.hasAttribute('data-ready')) {
      const dataString = this.el.getAttribute('data-ready');
      if (dataString.startsWith('range')) {
        const cleaned = dataString.replace('range:', '');
        let start, end;
        if (this.el.hasAttribute('data-cc-exp')) {
          start = new Date().getFullYear();
          end = start + 33
        } else {
          start = Number(cleaned.split('-')[0]);
          end = Number(cleaned.split('-')[1]);
        }

        const obj = {};
        for (let i = start; i <= end; i++) {
          obj[String(i)] = String(i);
        }
        return this.loadOptions(obj);
      }

      if (dataString.startsWith('env')) {
        let key = dataString.replace('env:', '');
        if (key.includes(".")) {
          let obj = window.app_env;
          key.split(".").forEach(keyPart => {
            if (obj[keyPart]) {
              obj = obj[keyPart]
            }
          })
          if (!obj && obj != window.app_env) return this.setReady(true);
          return this.loadOptions(obj);
        }
        let obj = window.app_env[key];
        if (!obj) return this.setReady(true);
        return this.loadOptions(obj);
      }

      if (dataString.startsWith('list')) {
        let list = dataString.replace('list:', '').split(",");
        let obj = {};
        list.forEach(item => { obj[item] = item });
        return this.loadOptions(obj);
      }

      if (dataString.startsWith('common')) {
        let key = dataString.replace('common:', '');
        if (key == "months") {
          return this.loadOptions({
            "January": 1,
            "February": 2,
            "March": 3,
            "April": 4,
            "May": 5,
            "June": 6,
            "July": 7,
            "August": 8,
            "September": 9,
            "October": 10,
            "November": 11,
            "December": 12,
          })
        }
        return this.setReady(true)
      }

      const dataParsed = JSON.parse(dataString);
      if (
        dataParsed instanceof Array &&
        dataParsed.length &&
        typeof dataParsed[0] != "object"
      ) {
        const obj = {};
        dataParsed.forEach(item => {
          obj[String(item)] = String(item);
        });
        return this.loadOptions(obj);
      }
      return this.loadOptions(dataParsed);
    }

    if (this.el.hasAttribute('data-get')) {
      this.el.setAttribute("aria-busy", true);
      this.field.classList.add('--loading');
      this.el.setAttribute('disabled', "");
      let path = this.el.getAttribute('data-get');
      if (path[0] == '/') {
        path = window.location.origin + path;
      }
      fetch(path)
        .then(response => response.json())
        .then(data => this.loadOptions(data))
        .catch(() => this.setReady(true));
    } else {
      this.setReady(true);
    }
  }

  attributeChangeHandler(mutation) {
    if (["data-ready", "data-get"].includes(mutation.attributeName)) {
      this.form.fieldUnready(this.key);
      this.getOptions();
    } else if (this.watchedAttributes.includes(mutation.attributeName)) {
      this.initRules();
    }
  }

  destroy() {
    if (!this.snapshot) return
    window.app_listeners?.remove('focus', `field-listbox-${this.el.id}`);
    window.app_listeners?.remove('click', `field-listbox-${this.el.id}`);
    window.app_listeners?.remove('resize', `field-${this.el.id}-position`);
    super.destroy();
  }

  blurHandler(e) {
    if (e.target != this.el) return

    setTimeout(() => {
      let active = document.activeElement;
      if (
        !this.field.contains(active)
        || active.classList.contains("field__button")
      ) {
        this.requiresValidation && this.validate();
        this.closeListbox(false, "blur");
      }
    }, 0);
  }

  setReady(force = false) {
    if (this.optionsData || force) {
      this.form.fieldReady(this.key);
      this.ready = true;
    }
  }

  positionHandler() {
    if (!this.listbox || this.el.hasAttribute('data-dropdown-bottom')) return
    if (Client.height - this.field.getBoundingClientRect().bottom < this.listbox.clientHeight + 40) {
      if (this.isEditabe && Client.isHandheldDevice()) return
      this.listbox.classList.add('--from-top');
      this.field.classList.add('--over-top');
    } else {
      this.listbox.classList.remove('--from-top');
      this.field.classList.remove('--over-top');
    }
  }

  openListbox(focusOption = true, reason) {
    if (!this.listbox || this.isListboxOpen) return
    if (this.isAutocomplete && !this.optionsData) return
    if (this.form.el.classList.contains('--display-view')) return
    if (this.form.isSubmitting) return
    this.field.classList.remove('--closing');
    this.listbox.classList.remove("--selected");
    if (this.isEditabe && this.queriedTerm != this.el.value.trim()) {
      this.queriedTerm = this.el.value.trim();
      this.hasFullQueryMatch = false;
      this.buildOptions(focusOption);
    }
    this.positionHandler();
    this.el.setAttribute('aria-expanded', true);
    this.isListboxOpen = true;
    this.field.classList.add('--active');
    this.el.parentElement?.classList.add('--focused');
    this.listbox.setAttribute('aria-hidden', false);
    focusOption && this.focusOption();
    window.app_listeners?.add('resize', `field-${this.el.id}-position`, this.positionHandler.bind(this))
  }

  closeListbox(focusInput = false, reason) {
    if (!this.listbox || !this.isListboxOpen) return
    if (
      this.forceValue
      && this.isEditabe
      && !this.isAutocomplete
      && this.el.value.trim() != ''
    ) {
      this.setValue(this.lastValue, true);
    }
    this.el.setAttribute('aria-expanded', false);
    this.field.classList.add('--closing');
    setTimeout(() => {
      if (!this.field.classList.contains('--closing')) return
      this.field.classList.remove('--active');
      this.field.classList.remove('--closing');
      this.listbox.classList.remove("--selected");
    }, 200);
    this.isListboxOpen = false;
    if (focusInput && document.activeElement != this.el) {
      this.el.focus();
      if (this.isEditabe) {
        this.el.selectionStart = this.el.selectionStart = 10000;
      }
    }
    this.el.parentElement?.classList.remove('--focused');
    this.focusOption();
    setTimeout(() => {
      this.listbox.setAttribute('aria-hidden', true);
    });

    window.app_listeners?.remove('resize', `field-${this.el.id}-position`);
  }

  focusOption(optionEl = null, byHover = false, focusLast = false) {
    let navigation = Boolean(optionEl);
    let focusedOption = this.listboxList?.querySelector("button.--focused");
    if (optionEl != focusedOption) {
      focusedOption?.classList.remove("--focused");
    }
    if (!this.isListboxOpen) {
      this.el.setAttribute("aria-activedescendant", "");
      return
    }
    if (!optionEl) {
      optionEl = this.listboxList?.querySelector(`[data-label="${this.el.value}"]`);
    }
    if (!optionEl) {
      let qString = `.listbox-item:${focusLast ? "last-child" : "first-child"}`;
      optionEl = this.listboxList?.querySelector(qString);
    }
    if (optionEl) {
      optionEl.classList.add("--focused");
      if (this.isAutocomplete && (this.queriedTerm || navigation) && !byHover) {
        let isDifferent = this.getLabel() != optionEl.getAttribute("data-label");
        if (this.isEditabe && isDifferent) {
          this.setValue(optionEl.getAttribute("data-label"))
          this.el.selectionStart = this.el.selectionStart = 10000;
        }
      }
      this.el.setAttribute("aria-activedescendant", optionEl.id);
      this.scrollListboxOption(optionEl);
    } else {
      this.el.setAttribute("aria-activedescendant", "");
    }
  }

  scrollListboxOption(el) {
    let holder = this.listboxScroll;
    let offset = this.scrollOffset;
    if (holder.scrollTop + holder.offsetHeight < el.offsetTop + el.offsetHeight) {
      holder.scrollTop = el.offsetTop + el.offsetHeight - holder.offsetHeight + offset;
    } else if (holder.scrollTop > el.offsetTop + 2) {
      holder.scrollTop = el.offsetTop - offset;
    }
  }

  keydownHandler(e) {
    let focused = this.listboxList?.querySelector("button.--focused");
    if (this.printablesTrigger && !this.isEditabe && !e.shiftKey && !e.ctrlKey && !e.altKey) {
      const alphaNumeric = new RegExp('[a-z0-9]', 'i');
      if (alphaNumeric.test(e.key) && e.key.length == 1) {
        this.printableCharacterHandler(e);
        return
      }
    }
    this.typeBuffer = "";

    if (e.code == "Escape") {
      if (this.isListboxOpen) {
        e.preventDefault();
        e.stopPropagation();
        this.closeListbox(true, e.code.toLowerCase());
        return
      } else if (this.el.value.trim() != "" && this.isEditabe) {
        e.preventDefault();
        e.stopPropagation();
        this.setValue("", true);
        this.el.dispatchEvent(new Event("input"));
        return
      }
    }

    if (
      ["Backspace", "Delete"].includes(e.code)
      && !this.isEditabe
      && this.el.value.trim() != ""
      && !this.el.hasAttribute("data-unclearable")
    ) {
      e.preventDefault();
      e.stopPropagation();
      this.setValue("", true);
      return
    }

    if (e.code == "Enter" || (e.code == "Space" && !this.isEditabe)) {
      if (this.isListboxOpen && this.optionsData) {
        e.preventDefault();
        focused ? focused.click() : this.closeListbox(true, e.code.toLowerCase());
      } else {
        if (this.isAutocomplete && this.isSubmitOnSelect) {
          this.setValue(this.filterRegEx(this.el), true);
          this.label && e.preventDefault();
          return
        }
        e.preventDefault();
        this.openListbox(true, e.code.toLowerCase());
      }
      return
    }

    if (e.code == "ArrowDown" || (e.code == "ArrowRight" && !this.isEditabe)) {
      e.preventDefault();
      if (this.isListboxOpen) {
        let next = this.getNextOption(focused);
        next && this.focusOption(next);
      } else {
        this.openListbox(true, e.code.toLowerCase());
      }
      return
    }

    if (e.code == "ArrowUp" || (e.code == "ArrowLeft" && !this.isEditabe)) {
      e.preventDefault();
      if (this.isListboxOpen) {
        let prev = this.getPrevOption(focused);
        prev && this.focusOption(prev);
      } else {
        this.openListbox(false, e.code.toLowerCase());
        this.focusOption(null, false, true);
      }
      return
    }
  }

  getNextOption(option) {
    let first = this.listboxList?.querySelector(".listbox-item:first-child");
    let last = this.listboxList?.querySelector(".listbox-item:last-child");

    if (option == last) return first
    let nextOption = option.nextElementSibling;
    if (!nextOption?.classList.contains("listbox-item")) {
      nextOption = nextOption?.nextElementSibling
    }
    return nextOption || first
  }

  getPrevOption(option) {
    let first = this.listboxList?.querySelector(".listbox-item:first-child");
    let last = this.listboxList?.querySelector(".listbox-item:last-child");

    if (option == first) return last
    let prevOption = option.previousElementSibling;
    if (!prevOption?.classList.contains("listbox-item")) {
      prevOption = prevOption?.previousElementSibling
    }
    return prevOption || last
  }

  printableCharacterHandler(e) {
    let char = e.key;
    let nextValue = null;
    if (this.typeBuffer !== char) {
      this.typeBuffer += char;
    }
    let expression = (term) => new RegExp(`\\b(${term})`, 'i');
    const name = this.key.replace("birthday", "");
    if (name.includes("month") || name.includes("day")) {
      expression = (term) => new RegExp(`\\b(0${term}|${term})`, 'i');
    }
    if (name.includes("year")) {
      expression = (term) => new RegExp(`\\b(19${term}|20${term}|${term})`, 'i');
    }
    let entries = Object.entries(this.optionsFlattened);
    let filteredData = entries.filter(([key, value]) => `${key} ${value}`.match(expression(this.typeBuffer)));
    if (filteredData.length == 0) {
      this.typeBuffer = char;
    }
    filteredData = entries.filter(([key, value]) => `${key} ${value}`.match(expression(this.typeBuffer)));
    if (filteredData.length == 0) {
      this.typeBuffer = '';
      return
    }
    if (this.el.value != '' && filteredData.find(([key, value]) => key === this.el.value)) {
      let currentIndex = 0;
      filteredData.forEach(([key, value], index) => {
        if (key === this.el.value) {
          currentIndex = index;
        }
      })
      if (currentIndex == filteredData.length - 1) {
        nextValue = filteredData[0][0];
      } else {
        nextValue = filteredData[currentIndex + 1][0];
      }
    } else {
      nextValue = filteredData[0][0];
    }
    if (nextValue) {
      let changed = this.setValue(nextValue, false);
      changed && this.handleSelect(true);
      this.isListboxOpen && this.focusOption();
      e.preventDefault();
    }
  }

  clickHandler(e) {
    let target = e.real_target || e.composedPath()[0];
    if (!e.composedPath().includes(this.field)) {
      this.isListboxOpen && this.closeListbox(true, "click-outside");
      return
    }
    if (target.getAttribute("role") == "option") {
      this.setValue(target.getAttribute("data-value"), true);
      setTimeout(() => this.closeListbox(true, "select"));
    }
    if (target == this.el) {
      if (this.isListboxOpen) {
        this.closeListbox(false, "click");
      } else if (!this.el.hasAttribute('disabled')) {
        this.openListbox(true, "click");
      }
    }
  }

  handleProxyInput() {
    if (this.proxy) {
      this.proxy.value = this.value;
      this.proxy.dispatchEvent(new Event('change'));
    }
  }

  getValue() {
    if (!this.optionsData) return this.el.value.trim()
    let value = this.el.value.trim();
    if (this.optionsFlattened.hasOwnProperty(value)) {
      return String(this.optionsFlattened[value]);
    }
    return value;
  }

  getLabel() {
    return this.el.value.trim()
  }

  getOption(key) {
    if (!this.optionsData || this.isAutocomplete) return { value: key, label: key }
    let label = null;
    if (this.optionsFlattened.hasOwnProperty(key)) {
      label = key;
      key = String(this.optionsFlattened[key]);
    }
    if (!label) {
      for (const item in this.optionsFlattened) {
        if (this.optionsFlattened[item] == key) {
          label = item
        }
      }
    }
    return {
      value: key,
      label: label || key
    }
  }

  getOptionByLabel(label) {
    if (!this.optionsData) return false
    if (this.optionsFlattened.hasOwnProperty(label)) {
      return this.optionsFlattened[label]
    }
    return false
  }

  manageListboxSelect() {
    if (!this.listboxList) return
    let formerEl = this.listboxList.querySelector(`[aria-selected="true"]`);
    let optionEl = this.listboxList.querySelector(`[data-value="${this.value}"]`);
    if (formerEl !== optionEl) {
      formerEl?.classList.remove("--current");
      this.listbox.classList.add("--selected");
      formerEl?.setAttribute("aria-selected", false);
      !this.isAutocomplete && optionEl?.classList.add("--current");
      optionEl?.setAttribute("aria-selected", true);
    }
  }

  setValue(value, trigger = false) {
    value = String(value).trim();
    let option = this.getOption(value);
    let changed = this.label != option.label;
    this.label = option.label;
    this.value = option.value;
    this.el.value = this.label;
    this.el.setAttribute("data-option-value", this.value);
    this.handlePlaceholder();
    this.manageListboxSelect();
    if (this.clone) {
      this.clone.value = String(this.value);
    }

    if (!trigger) return changed
    if (this.requiresValidation) {
      this.validate();
    }
    this.handleSelect();
    return changed
  }

  handleSelect(softSelect = false) {
    this.handleProxyInput();
    this.onSelect(this.label, this.value);
    this.el.dispatchEvent(new Event("select", { bubbles: true }));
    this.el.dispatchEvent(new Event(softSelect ? "soft-select" : "hard-select", { bubbles: true }));

    if (this.isSubmitOnSelect && this.label && !softSelect) {
      if (this.form.submitOnPage) {
        this.el.value = this.el.value.trim();
        this.filterInput(this.el);
        this.form.loading();
        this.form.isSubmitting = true;
        this.form.el._submit();
      } else {
        this.form.el.dispatchEvent(new Event("submit"));
      }
      return
    }
    if (String(this.el.value) != String(this.lastValue)) {
      this.lastValue = this.el.value;
      this.el.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }

  renderListbox() {
    if (!this.templates.listbox) return
    if (this.field.querySelector('.listbox')) return
    let atts = [`tabindex="-1"`, "data-ignore-tab"];
    let rendered = Render.interpolateString(this.templates.listbox, {
      input_name: this.name,
      atts: this.parseListboxAttributes(atts),
    })
    rendered = Render.fragmentFromString(rendered);
    this.field.querySelector('.field__wrapper')?.appendChild(rendered);
    this.listbox = this.field.querySelector('.listbox');
    if (this.field.classList.contains('--over-top')) {
      this.listbox.classList.add('--from-top');
    }
    this.listbox.id = `${this.el.id}-listbox`;
    this.listbox.style.setProperty('--max-results', this.maxOptionsVisible);
    this.listboxScroll = this.field.querySelector('.listbox__scroll');
    this.listboxContainer = this.field.querySelector('.listbox__container');
    this.listboxList = this.field.querySelector('.listbox__list');
    window.app_accessibility?.ariaHiddenElInit(this.listbox, document);
  }

  parseListboxAttributes(atts = []) {
    return atts.join(" ");
  }

  listboxResizeHandler() {
    let options = this.listboxList.querySelectorAll("button");
    if (options.length != 0) {
      this.listbox.querySelector('.listbox-empty')?.remove();
      this.listbox.classList.remove('--empty');
      if (options.length > this.maxOptionsVisible) {
        this.listbox.classList.remove('--no-scroll');
      } else {
        this.listbox.classList.add('--no-scroll');
      }
    } else {
      this.renderEmpty();
      this.listbox.classList.add('--empty');
      this.listbox.classList.add('--no-scroll');
    }
  }

  renderEmpty() {
    if (!this.listbox || !this.templates.empty) return
    this.listbox.querySelector('.listbox-empty')?.remove();
    let rendered = Render.interpolateString(this.templates.empty, {
      term: this.el.value,
      input_name: this.nameLowercase,
    });

    this.listboxContainer.appendChild(Render.fragmentFromString(rendered));
  }

  renderCloneOptions() {
    let finalString = `<option value=""></option>`;
    for (const item in this.optionsFlattened) {
      finalString += `<option value="${this.optionsFlattened[item]}">${item}</option>`;
    }
    this.clone.innerHTML = '';
    this.clone.appendChild(Render.fragmentFromString(finalString));
  }

  renderOptions(options) {
    if (!this.listbox) return
    let counter = 0;
    let finalString = '';
    if (!this.templates.item || !this.listboxList) return
    if (options instanceof Array) {
      options.forEach((group, index) => {
        if (index != 0) {
          finalString += this.templates.separator;
        }
        for (const value in group) {
          finalString += this.renderOption(value, group[value], counter);
          counter++;
        }
      })
    } else {
      for (const value in options) {
        finalString += this.renderOption(value, options[value], counter);
        counter++;
      }
    }
    this.listboxList.innerHTML = '';
    this.listboxList.appendChild(Render.fragmentFromString(finalString));
    this.listboxResizeHandler();
  }

  renderOption(label, value, counter) {
    let id = `${this.el.id}-option-${counter}`;
    let isSelected = label == this.getLabel();
    let atts = {
      "tabindex": "-1",
      "data-ignore-tab": ""
    };
    if (label == 'A-Z') {
      atts["aria-label"] = "ascending";
    } else if (label == 'Z-A') {
      atts["aria-label"] = "descending";
    } else {
      atts["aria-label"] = label;
    }
    let inner_text = `${label}`;
    label = this.isAutocomplete ? value : label;
    if (this.queriedTerm && this.isAutocomplete) {
      atts["aria-label"] = value;
      if (counter == 0) {
        inner_text = `<strong>${this.queriedTerm}</strong><span>- my search</span>`;
        atts["data-direct-search"] = "";
      }
    }
    // Highlight
    if (this.isEditabe && this.queriedTerm && !inner_text.includes("<strong>")) {
      let regex = new RegExp(`(${this.buildExpression(this.queriedTerm)})`, 'gi');
      inner_text = inner_text.replace(regex, function (match) {
        return `<strong>${match}</strong>`
      })
      inner_text = inner_text.replace("</strong><strong>", "");
    }

    if (this.innerTextTemplate) {
      inner_text = Render.interpolateString(this.innerTextTemplate, {
        value, inner_text, label
      })
    }

    let templateMap = this.optionTemplateMap({
      id, label, value, inner_text,
      atts: this.parseOptionAttributes(atts, { label, value, counter }),
      current: isSelected ? (this.isAutocomplete ? "--focused" : "--current") : "",
      current_boolean: isSelected ? "true" : "false",
    })

    return Render.interpolateString(this.templates.item, templateMap)
  }

  parseOptionAttributes(atts, option) {
    let attributes = [];
    for (const key in atts) {
      attributes.push(`${key}="${atts[key]}"`);
    }
    return attributes.join(" ")
  }

  loadOptions(data) {
    if (!data) return
    this.optionsData = this.optionsLoadFilter(data);
    this.optionsFlattened = Utility.flatten(this.optionsData);
    !this.isAutocomplete && this.setValue(this.el.value);
    this.renderListbox();
    this.renderOptions(this.optionsData);
    this.clone && this.renderCloneOptions();
    this.el.removeAttribute('disabled');
    this.field.classList.remove('--loading');
    this.el.removeAttribute("aria-busy");
    this.el.dispatchEvent(new Event('data-loaded'));
    this.setReady();
  }

  filterInput(input) {
    input.value = this.filterRegEx(input.value)
  }

  initProxy() {
    if (!this.proxy) return
    if (this.proxy.tagName == 'SELECT') {
      let optionsData = {};
      for (let opt of this.proxy.options) {
        if (opt.label !== "-- select an option --") {
          optionsData[opt.label] = opt.value;
        }
      }
      if (Object.keys(optionsData).length != 0) {
        this.form.fieldUnready(this.key);
        this.loadOptions(optionsData);
        if (this.value == "") {
          this.setValue(this.proxy.value);
        }
      }
    }
  }

  buildExpression(string) {
    return string.replace(new RegExp(/\W/, "ig"), match => `\\${match}`);
  }

  searchOptions() {
    if (!this.optionsData) return {};
    if (this.getOptionByLabel(this.queriedTerm) && !this.isAutocomplete) {
      this.hasFullQueryMatch = true;
      return this.optionsData
    }
    let expression = this.buildExpression(this.queriedTerm);
    if (this.optionsData instanceof Array) {
      let filtered = [];
      this.optionsData.forEach(group => {
        let filteredGroup = Object.entries(group).filter(([key, value]) => {
          if (this.isAutocomplete && this.queriedTerm && value === this.queriedTerm) {
            this.hasFullQueryMatch = true;
            return false
          }
          return `${key} ${value}`.match(new RegExp(`(${expression})`, 'gi'))
        })
        if (filteredGroup.length) {
          filteredGroup = Object.fromEntries(filteredGroup);
          filtered.push(filteredGroup);
        }
      })
      return this.optionsSearchFilter(filtered, this.queriedTerm)
    }

    let filtered = Object.entries(this.optionsData).filter(([key, value]) => {
      if (this.isAutocomplete && this.queriedTerm && value === this.queriedTerm) {
        this.hasFullQueryMatch = true;
        return false
      }
      return `${key} ${value}`.match(new RegExp(`(${expression})`, 'gi'))
    });
    let optionsData = {};
    if (this.queriedTerm && this.isAutocomplete) {
      optionsData[`<strong>${this.queriedTerm}</strong>`] = this.queriedTerm;
    }
    return {
      ...optionsData,
      ...this.optionsSearchFilter(Object.fromEntries(filtered), this.queriedTerm)
    }
  }

  buildOptions(focusOption = true) {
    if (!this.optionsData) return
    this.renderListbox();
    let options = this.searchOptions();
    this.renderOptions(options);
    !this.isListboxOpen && this.openListbox(false, "input");
    focusOption && this.focusOption();
  }

  handlePlaceholder() {
    if (this.isEditabe) return
    let placeholder = this.field.querySelector(".field__placeholder");
    if (this.el.value != "") {
      placeholder?.remove();
    } else if (this.el.placeholder && !placeholder) {
      this.el.parentElement.appendChild(Render.fragmentFromString(
        `<span class="field__placeholder">${this.el.placeholder}</span>`));
    }
  }

  inputHandler(e) {
    if (this.form.isSubmitting) return
    this.handlePlaceholder();
    if (!this.isEditabe) return
    this.filterInput(e.target);
    let term = e.target.value.trim();
    if (term === "") {
      this.setValue("", true);
    }
    this.queriedTerm = term;
    if (this.lastQueriedTerm === this.queriedTerm) return
    this.lastQueriedTerm = this.queriedTerm
    this.hasFullQueryMatch = false;
    this.listbox?.querySelectorAll(
      '.listbox-empty strong, button[data-direct-search] strong'
    ).forEach(update => { update.textContent = term });
    this.buildOptions();
  }
}

export default SelectField;