import Utility from "../../base/Utility";
import Flow from "./Flow";
import Client from "../../base/Client";
import SmashupPreview from "../product/preview/SmashupPreview";
import EcardPreview from "../product/preview/EcardPreview";
import InteractivePreview from "../product/preview/InteractivePreview";
import Preview from "../product/preview/Preview";
import SelfieSmashupPreview from "../product/preview/SelfieSmashupPreview";
import TalkingSmashupPreview from "../product/preview/TalkingSmashupPreview";
import Render from "../../base/Render";
import Format from "../../base/Format";
import Heartbeat  from "../../../../../../common/modules/utilities/Heartbeat";
import UnifiedBuilder from "../product/UnifiedBuilder";
import Saver from "../form/Saver";
import Helpers from "../../base/Helpers";


class SendFlow extends Flow {

  constructor(el) {
    super(el);
    this.externalForms = ["delivery-method"];
    this.order = { ...window.app_env?.ecard_order } || null;
    this.controllerType = 'fallback';
    this.isCompleted = this.order.is_completed;
    this.isRecovering = false;
    this.purchasedGift = false;
    this.supportsNativeShare = Boolean(window.navigator.share) && Client.isHandheldDevice();
    this.isResend = false;
    this.snapshot = {
      order: this.order
    };
    this.previewControllerMap = {
      smashup: SmashupPreview,
      ecard: EcardPreview,
      interactive: InteractivePreview,
      'talking-smashup': TalkingSmashupPreview,
      'selfie-smashup': SelfieSmashupPreview,
      fallback: Preview,
    }
    this.unifiedBuilder = null;
    this.saver = null;
    this.deliveryDetails = {
      selectGift: false,
      scheduled: false
    };
    this.preview = null;
    this.actionHandlerMap = {
      preview: this.triggerPreview.bind(this),
      edit: this.focusEditor.bind(this),
      share: this.shareHandler.bind(this),
      copy: this.copyHandler.bind(this),
      facebookShare: this.facebookShareHandler.bind(this),
      print: this.printHandler.bind(this),
    };
    this.previewRequestedController = null;
    this.previewRequested = false;
    this.successHandlerMap = {
      personalize: this.personalizeSuccessHandler.bind(this),
      "personalize-cac": this.personalizeCACSuccessHandler.bind(this),
      "delivery-method": this.deliveryMethodSuccessHandler.bind(this),
      "email-send": this.emailSendSuccessHandler.bind(this),
      "select-gift-form": this.giftSuccessHandler.bind(this),
    };
    this.isCopyingLink = false;
    this.initSendFlow();
  }

  printHandler(e) {
    if (this.order.product.model == "creatacard") {
      e.preventDefault();
      this.el.querySelector(".continue-to-print")?.click();
    }
  }

  focusEditor() {
    if (this.wizard.panels.has('personalize')) {
      this.setFlowStep("personalize", false);
      setTimeout(() => {
        this.forms.get('personalize')?.fields.get('pn1465')?.setFocus();
      }, 400);
    }
  }

  updateUrl() {
    if (window.location.pathname.includes("resend") && ["RFU", "REG"].includes(window.app_env?.customer.status) && !this.order.product.is_free) {
      let rfu_dialog = window.app_dialogs?.collection.get('rfu-resend');
      rfu_dialog.isBlocking = true;
      rfu_dialog.el.querySelector('.dialog__close').classList.add("hidden");
      window.app_dialogs.open('rfu-resend');
      return
    }
    if (window.location.pathname.includes("resend") && window.app_env?.customer.is_in_collections && !this.order.product.is_free) {
      let collections_dialog = window.app_dialogs?.collection.get('collections-dialog');
      collections_dialog.isBlocking = true;
      collections_dialog.el.querySelector('.dialog__close').classList.add("hidden");
      window.app_dialogs.activate('collections-dialog');
      return
    }
    if (window.location.pathname.includes("resend") && this.order && window.app_env?.customer) {
      let url = new URL(window.location.href);
      this.isResend = Boolean(this.getGift());
      url.pathname = `/${this.order.mode}/${this.order.id}${this.order.sender_id_fragment}`;
      window.history.replaceState({}, "unused", url.href);
    }
  }

  personalizeSuccessHandler(form) {
    if (form.content.hasOwnProperty("delivery_method")) {
      this.deliveryMethodSuccessHandler(form);
    } else {
      let wasKeyboardEvent = window.app_page?.wasKeyboardEvent;
      window.app_dialogs?.activate(
        "share-send",
        form.submitBtns[0],
        !wasKeyboardEvent
      );
    }
  }

  personalizeCACSuccessHandler(form) {
    if (form.content.hasOwnProperty("delivery_method")) {
      if (form.content.delivery_method == "print") {
        let response = form.lastRequest.response?.data;
        if (response) {
          window.app_services?.track("send-completed", {
            ...this.order, ...{
              id: response["print_item_number"],
              delivery_method: "print"
            }
          });
        }
      } else {
        setTimeout(() => {
          this.deliveryMethodSuccessHandler(form);
        }, 600);
      }
    }
  }

  deliveryMethodSuccessHandler(form) {
    window.app_dialogs?.close("share-send");
    let map = {
      email: "send-email",
      copy: "confirm-share",
      facebook: "confirm-share",
    };
    this.updateFlow({ delivery_method: form.content.delivery_method });
    setTimeout(() => {
      this.setFlowStep(map[form.content.delivery_method]);
    }, 200)
  }

  emailSendSuccessHandler(form) {
    this.updateFlow();
    if (
      form.content.hasOwnProperty("giftcard") &&
      form.content.giftcard &&
      this.order.gift == null
    ) {
      this.setFlowStep("select-gift");
    } else {
      form.persistLoading = true;
      this.setFlowStep("confirm-email");
    }
  }

  initGiftPanel() {
    let form = this.forms.get("select-gift-form");
    let panel = this.wizard.panels.get("select-gift");
    let iframeHolder = panel?.el.querySelector("#jifiti-merchant");
    if (!panel || !form || !iframeHolder) return
    let jifitiListener = new AbortController();

    let heartbeat = null;
    let iframeString = iframeHolder.innerHTML || "";

    // Render iframe before panel activation
    panel.el.addEventListener("panel-before-activation", () => {
      jifitiListener?.abort();
      jifitiListener = new AbortController();
      iframeHolder.innerHTML = "";
      iframeHolder.appendChild(Render.fragmentFromString(iframeString));
      let iframe = iframeHolder.querySelector("iframe");
      let loader = iframeHolder.querySelector(".lazy-load-iframe__loader");
      if (!iframe) return
      iframe.setAttribute("src", this.order.giftcard_iframe);
      iframe.addEventListener("load", () => {
        setTimeout(() => {
          iframeHolder.classList.add("--loaded");
          setTimeout(() => {
            loader.remove();
          }, 300)
        }, 500);
      });
      // Listen for message event
      window.addEventListener('message', e => {
        if (!e.origin.includes("jifiti.com") || e.data?.event != "success") return
        panel.disableNavigation();
        heartbeat?.kill();
        heartbeat = new Heartbeat(30, 15);
        heartbeat.onSuccess = (data) => {
          form.el.submit();
        }
        heartbeat.onFail = (reason) => {
          form.addError("server", "Unable to connect to merchant, please try again later.");
          form.renderErrors();
          panel.enableNavigation();
        }
        heartbeat.start(`${app_env.shost}/gifts/transaction-started/${this.order.id}${this.order.sender_id_fragment}`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Csrftoken': Client.getCSRFToken(),
          }
        });
      }, { signal: jifitiListener.signal });
    });

    // Clear iframe and errors after deactivation
    panel.el.addEventListener("panel-deactivated", () => {
      jifitiListener?.abort();
      form.clearErrors(["server"]);
      heartbeat?.kill();
      iframeHolder.classList.remove("--loaded");
      iframeHolder.innerHTML = "";
    });
  }

  giftSuccessHandler() {
    this.purchasedGift = true;
    window.app_services?.track("send-gift-attached", this.order);
    this.setFlowStep("confirm-email");
  }

  setFlowStep(key, focus = true) {
    if (key == "confirm-email") {
      this.sentHandler();
      this.hydrateConfirmEmail();
    } else if (key == "confirm-share") {
      this.sentHandler();
      this.hydrateConfirmShare();
    }

    if (key != this.wizard.activePanel?.name) {
      window.app_services?.tealium.view({
        page_type: "sendflow",
        page_sub_type: key,
      });
    }

    this.wizard?.step(key, focus);
    window.scrollTo({
      top: 0,
      behavior: "instant",
    })
  }

  sentHandler() {
    if (this.order.addressbook_updated) {
      window.app_tables?.invalidateCache()
    }
    if (this.order.mode == "edit") {
      window.history.replaceState({}, 'unused', window.location.href.replace("edit", "send"));
    }
    if (this.order.mode == "reply") {
      window.app_storage?.remove("ag-reply-token", "session");
    }
    if (this.order.mode != "edit" || this.purchasedGift) {
      window.app_services?.track("send-completed", this.order);
    }
    window.app_storage?.remove(`ag-pdp-${this.order.product.product_number}`, "session");
    this.clearFlow();
  }

  initSendFlow() {
    this.controllerType = this.order.product.model;
    this.initSendEmailForm();
    this.initExternalForms();
    this.manageNativeShare();
    this.manageMembershipStart();
    if (this.order.current_step == "confirm-email") {
      this.hydrateConfirmEmail();
    } else if (this.order.current_step == "confirm-share") {
      this.hydrateConfirmShare();
    }
    this.initPreview();
    this.initGiftPanel();
    this.forms.forEach((form) => {
      form.requestJSON = true;
      form.filterGetter = (data) => this.filterGetterHandler(data, form);
      if (form.el.id == "delivery-method") {
        form.onSubmit = () => {
          form.getFormData(true);
          if (
            form.content.hasOwnProperty("delivery_method") &&
            form.content.delivery_method == "email"
          ) {
            form.complete("custom");
            this.deliveryMethodSuccessHandler(form);
            return false;
          } else {
            return true;
          }
        };
      }
      if (form.el.id == "personalize") {
        form.onSubmit = () => {
          form.getFormData(true);
          if (
            (form.content.hasOwnProperty("pn1465") &&
              form.content.pn1465 == "") ||
            (this.order.personalizations?.Msg &&
              this.order.personalizations.Msg == form.content.pn1465)
          ) {
            form.complete("custom");
            this.personalizeSuccessHandler(form);
            this.updateFlow({
              personalizations: {
                ...this.order.personalizations,
                Msg: form.content.pn1465,
              },
            });
            return false;
          } else {
            return true;
          }
        };
      }
      if (this.successHandlerMap.hasOwnProperty(form.el.id)) {
        form.onSuccess = () => {
          this.updateFlow(form.lastRequest.response?.data);
          this.successHandlerMap[form.el.id](form);
          return true;
        };
      }
    });
    window.app_listeners?.add(
      "click",
      `flow-${this.el.id}-click`,
      this.clickHandler.bind(this)
    );

    if (this.order.product.model == "creatacard") {
      let ubElement = this.el.querySelector(".agub");
      if (ubElement) {
        this.unifiedBuilder = new UnifiedBuilder(ubElement);
      }
    }
    this.updateUrl();
    this.initSaver();
  }

  initSaver() {
    ["session", "local", "page", "cookie"].forEach((type) => {
      if (this.el.hasAttribute(`data-save-${type}`)) {
        let key = this.el.getAttribute(`data-save-${type}`) || `gs-${type}`;
        this.saver = new Saver(key, type, null, this.el.getAttribute("data-save-exclusive"));
        this.loadFlow();
      }
    });
    if (this.saver) {
      if (!this.saver?.load() && !this.isCompleted) {
        // Initial save if nothin was saved to remain in session storage
        window.app_services?.track("send-started", this.order);
        this.saveFlow();
      }
      window.app_forms?.collection.forEach(form => {
        if (!form.el.closest(`#${this.el.id}`)) return
        form.el.addEventListener("change", () => {
          if (this.isRecovering) return
          let formData = form.getFormData(true, true);
          this.saveFlow("form", form.id, formData);
        })
      })
      window.app_tables?.collection.forEach(table => {
        if (!table.el.closest(`#${this.el.id}`)) return
        table.el.addEventListener("change", () => {
          if (this.isRecovering) return
          this.saveFlow("table", table.el.id, table.entries);
        })
      })
      window.app_tabs?.collection.forEach(tabs => {
        if (!tabs.el.closest(`#${this.el.id}`)) return
        tabs.el.addEventListener("change", () => {
          if (this.isRecovering) return
          this.saveFlow("tabs", tabs.el.id, { tab: tabs.activeKey });
        })
      })
      this.wizard.onPanelChange = () => {
        this.order.current_step = this.wizard.activePanel.name;
        if (this.isRecovering) return
        this.saveFlow();
      }
    }
  }

  recovering(isRecovering = true) {
    this.isRecovering = isRecovering;
    if (isRecovering) {
      this.el.classList.add("--recovering");
    } else {
      this.el.classList.add("--recovered");
      setTimeout(() => {
        this.el.classList.remove("--recovering");
      }, 300)
    }
  }

  loadFlow() {
    let recoveredData = this.saver.load();
    if (!recoveredData) return
    if (["confirm-email", "confirm-share"].includes(recoveredData.order.step)) {
      this.clearFlow();
      return
    }
    if (!this.order.steps.includes(recoveredData.order.current_step)) {
      this.clearFlow();
      return
    }
    let silentRecover = false;
    if (Object.keys(recoveredData).length == 1
      && recoveredData.order
      && recoveredData.order.current_step == this.order.current_step) {
      silentRecover = true;
    }
    !silentRecover && this.recovering();
    this.snapshot = recoveredData;
    this.isResend = Boolean(recoveredData.isResend);
    setTimeout(() => {
      if (recoveredData.order) {
        this.order = recoveredData.order;
      }
      if (recoveredData.tabs) {
        for (let key in recoveredData.tabs) {
          window.app_tabs?.collection.get(key)?.activate(recoveredData.tabs[key]["tab"], true)
        }
      }
      if (recoveredData.forms) {
        for (let key in recoveredData.forms) {
          window.app_forms?.collection.get(key)?.fill(recoveredData.forms[key])
        }
      }
      if (recoveredData.tables) {
        for (let key in recoveredData.tables) {
          let table = window.app_tables?.collection.get(key);
          if (table) {
            table.entries = recoveredData.tables[key];
            table.data = recoveredData.tables[key];
            table.dataReady();
          }
        }
      }
      this.setFlowStep(this.order.current_step)
    }, 300);

    // Here add handling for populating the flow with recovered personalisations
    if (!silentRecover) {
      setTimeout(() => {
        this.recovering(false);
      }, 1000)
    }
  }

  saveFlow(saveType = "order", key = null, dataToSave = {}) {
    if (!this.saver || this.isRecovering) return
    if (["personalize-cac"].includes(this.order.current_step)) return
    if (["confirm-email", "confirm-share"].includes(this.order.current_step)) {
      this.clearFlow();
      return
    }
    if (saveType == "order") {
      this.snapshot["order"] = this.order;
      this.snapshot["isResend"] = this.isResend;
    } else if (saveType == "table") {
      if (!this.snapshot.tables) {
        this.snapshot["tables"] = {}
      }
      this.snapshot["tables"][key] = dataToSave;
    } else if (saveType == "form") {
      if (!this.snapshot.forms) {
        this.snapshot["forms"] = {}
      }
      this.snapshot["forms"][key] = dataToSave;
    } else if (saveType == "tabs") {
      if (!this.snapshot.tabs) {
        this.snapshot["tabs"] = {}
      }
      this.snapshot["tabs"][key] = dataToSave;
    }
    this.saver.save(this.snapshot, this.el.getAttribute("data-save-clear-regex") || null);
  }

  clearFlow() {
    this.saver?.clear();
  }

  initPreview() {
    let previewEl = document.querySelector('.product-preview');
    if (previewEl) {
      if (this.previewControllerMap.hasOwnProperty(this.controllerType)) {
        this.preview = new this.previewControllerMap[this.controllerType](previewEl, this.order.product, this.order.personalizations);
      } else {
        this.preview = new this.previewControllerMap.fallback(previewEl, this.order.product, this.order.personalizations);
      }
    }
  }

  manageMembershipStart() {
    document.addEventListener("app-customer-membership-started", e => {
      Utility.updateContent({
        "send-email-description": "Add recipient(s) for your greeting, customize your name, send now or schedule up to a year in advance."
      }, this.el)
    })
  }

  clickHandler(e) {
    let target = e.real_target || e.target;
    let key = target?.getAttribute("data-flow-action") || null;
    if (e.isTrusted) {
      this.previewRequestedController?.abort();
      this.el.querySelectorAll('button[data-flow-action="preview"]').forEach(el => el.classList.remove('--loading'));
    }
    if (key && this.actionHandlerMap.hasOwnProperty(key)) {
      this.actionHandlerMap[key](e);
    }
  }

  triggerPreview(e) {

    if (this.order.product.model == "creatacard") {
      e.preventDefault();
      this.el.querySelector(".ub-preview-button")?.click();
      return
    }

    let form = this.forms.get("personalize");
    this.previewRequestedController = new AbortController();
    e.target.classList.add('--loading');
    if (form && !form.submitDisabled) {
      const frontendValid = form.validate();
      if (frontendValid) {
        form.syncErrors();
        form.renderErrors();
        this.preview.setMessage(form.fields.get('pn1465').getValue());
        if (this.preview.isReady) {
          this.openPreview(e);
        } else {
          this.preview.el.addEventListener('preview-ready', () => {
            this.openPreview(e);
          }, { signal: this.previewRequestedController.signal })
        }
      } else {
        this.el.querySelectorAll('button[data-flow-action="preview"]').forEach(el => el.classList.remove('--loading'));
        form.complete("error");
      }
    } else {
      if (this.preview.isReady) {
        this.openPreview(e);
      } else {
        this.preview.el.addEventListener('preview-ready', () => {
          this.openPreview(e);
        }, { signal: this.previewRequestedController.signal })
      }
    }
  }

  openPreview(e) {
    this.el.querySelectorAll('button[data-flow-action="preview"]').forEach(el => { el.classList.remove('--loading') });
    // Show edit/add message cta only for personalize and send-email panels of the flow.
    let canEdit = ["send-email", "personalize"].includes(this.wizard.activePanel.name);
    Utility.updateContent({
      "edit-trigger": {
        addClass: canEdit ? "" : "hidden",
        removeClass: canEdit ? "hidden" : ""
      }
    })
    window.app_dialogs?.activate("send-preview", e?.target);
  }

  updateFlow(updatedOrder) {
    this.order = { ...this.order, ...updatedOrder };
    this.saveFlow();
  }

  filterGetterHandler(data, form) {
    this.isCompleted = false;
    this.order.gift = this.getGift();
    let panel = form.el.closest(".wizard-panel");
    let step = panel?.getAttribute("data-name") || form.el.id;
    let product_number = Number(this.order?.product?.product_number) || null;
    let update = { step, product_number };
    if (this.order.product.model != "creatacard") {
      let pn1595 = this.order?.personalizations?.NETA || null;
      if (pn1595) {
        update["pn1595"] = pn1595;
      }
      let LobsterId = this.order?.personalizations?.LobsterId || null;
      if (LobsterId) {
        update["pn1595"] = encodeURIComponent(JSON.stringify({ LobsterId }));
      }
    }
    let chatGPT = this.order?.personalizations?.chatGPT || null;
    if (chatGPT) {
      update["generate_message"] = chatGPT;
    }
    let pn1465 = this.order?.personalizations?.Msg || null;
    if (pn1465 && step != "personalize") {
      update["pn1465"] = pn1465;
    }

    if (
      ["delivery-method", "personalize-cac"].includes(step) &&
      ["copy", "facebook", "print"].includes(data.delivery_method)
    ) {
        update['send'] = true;
    }

    if (step == "send-email" && (!data.giftcard || this.getGift())) {
      if (this.getGift()) {
        update["giftcard"] = "1";
      }
      update['send'] = true;
    }

    if (step == "send-email" && this.order.product.model == "creatacard") {
      update = this.appendUBPers(update);
    }

    if (step == "select-gift") {
      if (this.forms.has("email-send")) {
        update = { ...this.forms.get("email-send").content, ...update };
      }
      if (this.order.product.model == "creatacard") {
        update = this.appendUBPers(update);
      }
      update['send'] = true;
    }

    if (!data.hasOwnProperty('csrfmiddlewaretoken')) {
      update['csrfmiddlewaretoken'] = Client.getCSRFToken();
    }

    if (data.hasOwnProperty("recipients")) {
      let recipients = [];
      for (const key in data.recipients) {
        recipients.push(data.recipients[key]);
      }
      data.recipients = recipients;
    }

    if (data.hasOwnProperty("sender_name")) {
      let sender_name = data["sender_name"];
      let sender_email = window.app_env.is_signed_in ? app_env.customer.email : "";
      delete data.sender_name;

      if (data.hasOwnProperty("sender_email")) {
        sender_email = data["sender_email"];
        delete data.sender_email;
      }

      data["sender"] = {
        name: sender_name,
        email: sender_email
      }
    }

    // Add Client Timezone Offset
    let offsetMinutes = new Date().getTimezoneOffset();
    let offsetHours = offsetMinutes === 0 ? 0 : -(offsetMinutes / 60);
    update["client_utcoffset"] = Math.round(offsetHours);

    // Add Generation Reporting
    if (update.send && app_forms.collection.has("ai-message") && this.order?.personalizations?.Msg) {
      let generateForm = app_forms.collection.get("ai-message");
      let generateContent = generateForm.getFormData(true);
      let features_used = this.order.features_used || [];
      if (this.order?.personalizations?.Msg) {
        let feature = Utility.getFeatureCode(generateContent.message_state, "message_state");
        if (!features_used.includes(feature)) {
          features_used.push(feature);
        }
        update["generate_message"] = generateContent.generate_message;
        update["features_used"] = JSON.stringify(features_used);
      }
    }

    this.isCompleted = update.hasOwnProperty('send');
    if (update['send'] && this.order.steps.includes("checkout-pps")) {
      update['send'] = false;
      update['checkout'] = "pps";
    }
    return { ...data, ...update };
  }

  appendUBPers(update) {
    if (this.forms.has("personalize-cac")) {
      update = { ...this.forms.get("personalize-cac").content, ...update }
    } else if (this.order.personalizations?.LobsterId) {
      let cac_pers = {}
      for (let key in this.order.personalizations) {
        if (["pending_recip", "gift", "chatGPT"].includes(key)) continue
        cac_pers[key] = this.order.personalizations[key];
      }
      update = {
        "cac_pers": cac_pers,
        "features_used": JSON.stringify(this.order.features_used),
        ...update
      }
    }
    return update;
  }

  initSendEmailForm() {
    let form = this.forms.get("email-send");
    if (!form) return;
    this.checkFutureDate();
    this.hydrateDeliveryDetails();
    let giftField = form.fields.get("giftcard");
    if (giftField) {
      giftField.onChange = (input) => {
        this.deliveryDetails.selectGift = input.checked;
        this.hydrateDeliveryDetails();
      };
    }
    let dateField = form.fields.get("delivery_date");
    if (dateField) {
      dateField.onChange = () => {
        this.checkFutureDate(dateField.getLocalValue());
        this.hydrateDeliveryDetails();
      }
    }
  }

  checkFutureDate(targetValue = false) {
    let targetDate = targetValue ? new Date(targetValue) : this.getDeliveryDate();
    let daysLeft = Helpers.getDateDelta(new Date(), targetDate, "days");
    this.deliveryDetails.scheduled = daysLeft > 0;
  }

  hydrateDeliveryDetails() {
    const selectGift = this.deliveryDetails.selectGift;
    const scheduled = this.deliveryDetails.scheduled;
    const hasGift = this.getGift();

    const getSubmitLabel = () => {
      let action = scheduled ? "Schedule" : "Send";
      if (this.isResend) {
        action = "Resend"
      }
      if (selectGift && !hasGift) {
        return `Add Gift & ${action}`
      } else if (this.order.steps.includes("checkout-pps")) {
        return `Continue to Checkout`
      } else {
        return `${action} Greeting`
      }
    }

    Utility.updateContent({
      delivery_submit_label: getSubmitLabel(),
      delivery_submit: {
        addClass: selectGift || hasGift ? "--has-gift" : "",
        removeClass: !(selectGift || hasGift) ? "--has-gift" : "",
        "aria-label": getSubmitLabel(),
      },
    });
    if (this.order.steps.includes("checkout-pps")) {
      Utility.updateContent({
        "delivery-button-icon": {
          addClass: "hidden"
        },
        "add-recipients-button": {
          addClass: "dialog-trigger",
          "aria-haspopup": "dialog",
          "aria-controls": "locked-multiple-recipients",
          "data-action": ""
        },
      });
    }
    if (hasGift && !this.el.classList.contains('--has-gift')) {
      this.el.classList.add('--has-gift');
      Utility.updateContent({
        "recipients-table": {
          addClass: "--table-locked"
        },
        "gift_img": {
          "data-src": this.order.gift.image_url,
          alt: this.order.gift.title
        },
        "gift_description": this.order.gift.title,
      });
    }
  }

  copyHandler(e) {
    if (this.isCopyingLink) return
    this.isCopyingLink = true;
    const buttonText = e.target.querySelector(".btn__text");
    const initialText = buttonText.textContent;
    const message = this.order.delivery_details.short_url;
    const manageInnerCopy = () => {
      buttonText.textContent = "Copied!";
      setTimeout(() => {
        this.isCopyingLink = false;
        buttonText.textContent = initialText;
      }, 1000);
    }
    const copyFallback = () => {
      Utility.copyFallback(message);
      manageInnerCopy();
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(message).then(manageInnerCopy, copyFallback);
    } else {
      copyFallback();
    }
  }

  shareHandler() {
    window.navigator?.share?.({
      title: `Your Greeting from ${Render.html(this.order.sender.name)} | American Greetings`,
      url: this.order.delivery_details.short_url,
    });
  }

  facebookShareHandler(e = null) {
    e?.preventDefault();
    let facebookUrl = new URL("https://www.facebook.com/share.php");
    facebookUrl.searchParams.append("u", this.order.delivery_details.short_url);
    window.open(facebookUrl, 'popup', 'width=555,height=599,top=50,left=50');
  }

  hydrateConfirmShare() {
    this.hydrateGift();
    if(this.order.order_summary) {
      this.el.classList.add(`--has-order-summary`);
    }
    const templateQuery = `.confirm-share-${this.order.delivery_method}-template`;
    const template = this.el.querySelector(templateQuery);
    const content = Render.interpolateTemplate(template, {
      pickup_url: this.order.delivery_details.short_url
    }, true)
    Utility.updateContent({
      "confirm-share-content": content,
      "confirm-view-url": { "href": this.order.delivery_details.view_url },
      "confirm-resend-url": { "href": this.order.delivery_details.resend_url },
    })
    this.manageNativeShare();
    setTimeout(() => {
      if (this.supportsNativeShare) {
        this.shareHandler();
      } else if (this.order.delivery_method == "facebook") {
        this.facebookShareHandler();
      }
    }, 1000);
  }

  manageNativeShare() {
    Utility.updateContent({
      "default-share": {
        addClass: this.supportsNativeShare ? "hidden" : ""
      },
      "native-share": {
        removeClass: this.supportsNativeShare ? "hidden" : ""
      }
    })
  }

  hydrateRecipientTable() {
    let table = window.app_tables.collection.get("recipients-confirm");
    let recipinetsTable = window.app_tables.collection.get("recipients");
    if (!table || !recipinetsTable) return;
    table.data = recipinetsTable.data;
    table.entries = recipinetsTable.entries;
    table.renderItems(table.entries);
    table.el.classList.remove("--no-entries");
    table.dialog?.el.classList.remove("--no-entries");
  }

  getGift() {
    return Boolean(this.order.gift) && Object.keys(this.order.gift).length != 0 ? this.order.gift : null
  }

  hydrateConfirmEmail() {
    this.hydrateGift();
    this.hydrateReminders();
    this.hydrateRecipientTable();
    if(this.order.order_summary) {
      this.el.classList.add(`--has-order-summary`);
    }
    let product_label = `${this.order.product.model_label}${this.getGift() ? " and Gift Card" : ""}`;

    Utility.updateContent({
      "confirm-email-panel": {
        "aria-label": `You Have Successfully ${this.order.is_future ? 'Scheduled' : 'Sent'} your ${product_label}!`,
        "data-title": `${product_label} was ${this.order.is_future ? 'Scheduled' : 'Sent'} | American Greetings`,
      },
      "confirm-email-title": this.getConfirmEmailTitle(product_label),
      "confirm-email-description": this.getConfirmEmailDescription(),
      "confirm-mailbox-link": {
        "inner": this.order.is_future ? "Scheduled Cards" : "Sent Cards",
        "href": this.order.is_future ? "/myaccount/ecardsscheduled" : "/myaccount/ecardssent"
      },
      "confirm-view-url": { "href": this.order.delivery_details.view_url },
      "confirm-resend-url": { "href": this.order.delivery_details.resend_url }
    })

    if (this.order.mode == "giftcard-first") {
      Utility.updateContent({
        confirm_image: {
          "data-src": this.order.product.thumb
        }
      })
    }
  }

  getConfirmEmailTitle(product_label) {
    return this.order.is_future
      ? `Your ${product_label} Is On Its Way!`
      : `You Have Successfully Sent Your ${product_label}!`
  }

  getConfirmEmailDescription() {
    let deliverables = this.order.product.model_label;
    if (this.getGift()) {
      deliverables += ` and ${this.order.gift.title}`
    }
    let recipient = this.order.recipients[0] || {};
    let multiple_recipinets = "";
    if (this.order.recipients.length > 1) {
      let template = this.el.querySelector(".confirm-multiple-recipients-button-template");
      if (!template) return
      let label = this.order.recipients.length == 2 ? "other" : "others";
      let recipients_count = `+${this.order.recipients.length - 1} ${label}`;
      multiple_recipinets = Render.interpolateTemplate(template, { recipients_count }, true);
      multiple_recipinets = ` ${multiple_recipinets.trim()}`;
    }
    return this.order.is_future
      ? `This ${deliverables} will be sent to ${recipient.name} at ${recipient.email}${multiple_recipinets} on ${this.getDeliveryDate(true)}.`
      : `This ${deliverables} was sent to ${recipient.name} at ${recipient.email}${multiple_recipinets}.`
  }

  getDeliveryDate(format = false) {
    if (!this.order.delivery_date) return null
    let date = Helpers.convertDateTimeFromServer(this.order.delivery_date);
    if (format) {
      return date.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    }

    return date
  }

  hydrateGift() {
    this.order.gift = this.getGift();
    if (this.order.gift) {
      this.el.classList.add("--has-gift")
    } else {
      this.el.classList.remove("--has-gift")
    }
    Utility.updateContent({
      "gift_img": {
        "data-src": this.order.gift ? this.order.gift.image_url : "",
        "alt": this.order.gift ? this.order.gift.title : "",
        "title": this.order.gift ? this.order.gift.title : ""
      },
      "gift_description": this.order.gift ? this.order.gift.title : ""
    })
  }

  hydrateReminders() {
    this.el.classList.remove("--has-anniversary-reminder", "--has-birthday-reminder");
    if (!this.order.contact_reminder || !this.order.contact_reminder.hasOwnProperty("occasion_ids")) return
    let eventsMap = {
      1: {
        "label": "Birthday",
        "slug": "birthday"
      },
      2: {
        "label": "Anniversary",
        "slug": "anniversary"
      },
    }
    let activeReminder = eventsMap[this.order.contact_reminder.occasion_ids[0]];
    let date = this.getDeliveryDate();
    let reminderForm = window.app_forms.collection.get("confirm-reminders");
    let isSingleEvent = this.order.contact_reminder.occasion_ids.length == 1;
    let contactDetails = Format.contactToForm(this.order.contact_reminder);
    this.order.contact_reminder.occasion_ids?.forEach(id => {
      let slug = eventsMap[id].slug;
      this.el.classList.add(`--has-${slug}-reminder`);
      if (activeReminder.slug == slug) {
        contactDetails[`${slug}_reminder`] = 1;
      }
      contactDetails[`${slug}_month`] = date.getMonth() + 1;
      contactDetails[`${slug}_day`] = date.getDate();
    })
    let recipient = this.order.recipients[0];
    Utility.updateContent({
      "confirm-reminder-title": isSingleEvent
        ? `Add ${activeReminder.label} Reminder for ${recipient.name}`
        : `Add Reminders for ${recipient.name}`,
      "confirm-reminder-subtitle": recipient.email
    })
    reminderForm.disableTransition();
    reminderForm.fill(contactDetails);
    setTimeout(() => {
      reminderForm?.enableTransition();
    }, 200);
  }
}

export default SendFlow;