class Heartbeat {

  constructor(bpm = 30, tries = 30) {
    this.endpoint = null;
    this.bpm = bpm;
    this.interval = 60 / bpm * 1000;
    this.isBeating = false;
    this.triesCount = 0;
    this.maxTries = tries;
    this.response = null;
    this.method = "POST";
    this.options = {};
    // Hooks
    this.onPulse = (response) => { };
    this.onFail = (error) => { };
  }

  kill() {
    this.isBeating = false;
  }

  start(endpoint, options = {}) {
    if (!endpoint) return
    this.options = options;
    this.triesCount = 0;
    this.isBeating = true;
    this.endpoint = endpoint;
    this.beat();
  }

  async getPulse(response) {
    if (!response) {
      this.isBeating = false;
      return false
    }
    if (response.status == 200) {
      this.response = await response.json();
      return true;
    } else if (response.status == 206) {
      return false;
    } else {
      this.isBeating = false;
      return false;
    }
  }

  async beat() {
    if (this.triesCount >= this.maxTries || !this.isBeating) {
      this.onFail(this.isBeating ? "limit" : "aborted");
      this.isBeating = false;
      return
    }
    this.triesCount++;
    try {
      let response = await fetch(this.endpoint, this.options);
      let hasPulse = await this.getPulse(response);
      if (hasPulse) {
        this.onPulse(this.response);
      } else {
        if (this.isBeating) {
          setTimeout(this.beat.bind(this), this.interval)
        } else {
          this.onFail(this.triesCount >= this.maxTries ? "limit" : "aborted")
        }
      }
    } catch (error) {
      this.onFail("exception");
    }
  }
}

export default Heartbeat;