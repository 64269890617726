import Utility from "../../../base/Utility";
import ProductHelpers from "../../../utilities/ProductHelpers";
import BaseProduct from "./BaseProduct";

class SmashupProduct extends BaseProduct {
  constructor(el, parent, type, env=null) {
    super(el, parent, type, env);
    this.productControls = [];
    this.initSmashup();
  }

  initSmashup() {
    this.el.classList.add('--waits-visible');
    this.loadProduct();
    document.addEventListener('afterFormSubmit', e => {
      const obj = e.detail;
      if (this.form && obj && obj == this.form && this.form.status != 'success') {
        this.container.querySelector('#playlist-loader')?.style.setProperty("display", "none");
        this.iframe?.contentWindow.document.querySelector('#playlist-loader')?.style.setProperty("display", "none");
      }
    }, {signal: this.listeners.signal})
    window.app_listeners?.add(
      'pageshow',
      `bfcache-resets-${this.product.product_number}`,
      this.pageshowHandler.bind(this));
    Utility.elementRendered('#agi-expressions-card-container', this.container).then((el) => {
      let style = el.currentStyle || window.getComputedStyle(el, false);
      let src = style.backgroundImage.slice(4, -1).replace(/"/g, "");
      let image = new Image();
      image.addEventListener('load', () => {
        ProductHelpers.manageSmashupButtons(
          this.container,
          this.env?.can_send || this.env?.customer?.is_in_collections
        );
        setTimeout(() => {
          this.ready();
        }, 200);
      }, {signal: this.listeners.signal});
      image.src = src;
    })
  };

  onReady() {
    this.productControls = this.container.querySelectorAll("#card-controls input, #card-controls select");
    let productIframeControls = this.iframe?.contentWindow.document.querySelectorAll("#card-controls input, #card-controls select");
    if (productIframeControls) {
      this.productControls = [...this.productControls, ...productIframeControls];
    }
    let snapshot = this.form?.saver?.load() || null;
    this.productControls.forEach(control => {
      control.addEventListener("change", this.productInnerChangeHandler.bind(this), {signal: this.listeners.signal})
      if (snapshot?.hasOwnProperty(control.name) && snapshot[control.name] != "") {
        control.value = snapshot[control.name];
        control.dispatchEvent(new Event("change"));
      }
    })
  }

  productInnerChangeHandler(e) {
    let snapshot = {};
    let options = {};
    this.productControls.forEach(control => {
      if (!["default", ""].includes(control.value)) {
        snapshot[control.name] = control.value;
        options[this.getOptionName(control)] = this.getOptionValue(control)
      }
    })

    if (this.form) {
      this.form.extraRequestParams["smashup-options"] = JSON.stringify(options);
      this.form.saver?.save(snapshot);
    }
  }

  getOptionName(control) {
    if (control.hasAttribute("data-option-name")) {
      return control.getAttribute("data-option-name");
    } else {
      let name = control.name;
      if (control.tagName == "SELECT") {
        name = control.options[0].text
      } else if (control.tagName == "INPUT") {
        name = control.placeholder || "Text"
      }
      name = name.replace("Type ", "");
      name = name.replace("Choose ", "");
      name = name.replace("Select ", "");
      control.setAttribute("data-option-name", name);
      return name;
    }
  }

  getOptionValue(control) {
    if (control.tagName == "SELECT") {
      return control.options[control.selectedIndex].text
    } else if (control.tagName == "INPUT") {
      return control.value
    }
  }

  pageshowHandler(e) {
    if (e.persisted) {
      this.container.querySelector('#playlist-loader')?.style.setProperty("display", "none");
      this.iframe?.contentWindow.document.querySelector('#playlist-loader')?.style.setProperty("display", "none");
    }
  }

  initListeners() {
    this.productInterface.addEventListener(agi.ProductEvent.SEND_BUTTON_CLICKED, (e) => {
      this.triggerSend(e)
    });
  }

  triggerSend(e) {
    if (this.isSending) return
    this.isSending = true;
    let pers = this.getPersonalizations(e);
    if (!pers) return
    this.setFormPersonalizations(pers);
    this.form?.el.submit();
  }

  destroy() {
    window.app_listeners?.remove('pageshow', `bfcache-resets-${this.product.product_number}`);
    super.destroy();
  }

}

export default SmashupProduct;