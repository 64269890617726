import Client from "../base/Client";
import Utility from "../base/Utility";

class Carousel {
  constructor(el) {
    this.el = el;
    this.sliderEl = el.querySelector('.carousel__slider') || null;
    this.swiper = null;
    this.swiperSpeed = 300;
    this.isInit = false;
    this.type = "fallback";
    this.autoplaySpeed = 8000;
    this.isAutoPlay = null;
    this.autoPlayBtn = null;
    this.config = {
      loop: false,
      slidesPerView: 'auto',
      centeredSlides: false,
      slideToClickedSlide: false,
    };
    this.swiperButtons = el.querySelectorAll('.carousel__button') || null;
  }

  init() {
    if (this.isInit) return
    this.isInit = true;
    this.type = this.el.dataset.type || null;
    this.autoplaySpeed = Number(this.el.dataset.autoplaySpeed) * 1000;
    if (['--billboard-banner','--reviews','--overlay'].includes(this.type)) {
      this.isAutoPlay = true;
      this.autoPlayBtn = this.el.querySelector('.--autoplay');
      this.config.autoplay = {delay: this.autoplaySpeed};
      this.config.loop = true;
      this.config.watchSlidesProgress = true;
      this.config.pagination = {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletClass: 'carousel__bullet',
        bulletActiveClass: '--active-bullet'
      };
    }
    Utility.loadResources('swiper-library',
      ['https://cdnjs.cloudflare.com/ajax/libs/Swiper/11.0.5/swiper-bundle.min.js'],
      this.swiperInit.bind(this));
  }

  swiperInit() {
    if (!this.sliderEl) return
    this.swiper = new Swiper(this.sliderEl, this.config);
    this.swiperNavigation();
    this.swiperPlayPause();
    this.swiperUpdate();
    this.resizeHandler();
    window.app_listeners?.add('resize', `${this.el.id}-resize`, this.resizeHandler.bind(this));
    this.el.classList.add('--init');
    if (['--billboard-banner','--reviews','--overlay'].includes(this.type)) {
      this.updateActive();
      this.swiper.on('slideChange', this.updateActive.bind(this))
    }
  }

  updateActive() {
    const slides = this.el.querySelectorAll('.swiper-slide');
    slides.forEach( (slide, index) => {
      if (index == this.swiper.activeIndex) {
        window.app_accessibility?.ariaHiddenElInit(slide);
        slide.setAttribute('aria-hidden', false);
        Utility.loadImages(slide);
      } else {
        slide.setAttribute('aria-hidden', true);
      }
    });
  }

  resizeHandler() {
    if(['--billboard-banner','--reviews','--overlay'].includes(this.type) && Client.width > 767) {
      this.el.style.setProperty('--slide-height', '');
      return;
    }
    if(this.type == '--reviews' && Client.width > 767) {
      this.el.style.setProperty('--slide-height', '');
      return;
    }
    let img = this.el.querySelector('.carousel__item.swiper-slide-active img');
    if (!img || Client.isHidden(img)) {
      img = this.el.querySelector('.carousel__item.swiper-slide-active .--mobile img');
    }
    if (img) {
      if(this.type == '--content') {
        this.el.style.setProperty('--slide-height', this.el.querySelector('.carousel__item').getBoundingClientRect().height + 'px');
      } else {
        this.el.style.setProperty('--slide-height', img.parentElement.getBoundingClientRect().height + 'px');
      }
    }
    setTimeout(() => {
      this.swiper?.update()
    }, 100)
  }

  enableControl(el) {
    el.setAttribute('aria-disabled', false);
    el.classList.remove('--disabled');
    el.disabled = false;
  }

  disableControl(el) {
    el.setAttribute('aria-disabled', true);
    el.classList.add('--disabled');
    el.disabled = true;
  }

  updateButtonState(el) {
    if (!this.swiper) return;
    if (this.config.loop) return;
    if (el.classList.contains('--next')) {
      if (this.swiper.isEnd) {
        this.disableControl(el);
      } else {
        this.enableControl(el);
      }
    } else {
      if (this.swiper.isBeginning) {
        this.disableControl(el);
      } else {
        this.enableControl(el);
      }
    }
  }

  swiperNavigation() {
    if (!this.swiper || !this.swiperButtons || this.swiperButtons.length == 0) return
    this.swiperButtons.forEach((button) => {
      if(button.classList.contains('--autoplay')) return;
      button.setAttribute('aria-controls', this.sliderEl.firstElementChild.id);
      this.updateButtonState(button);
      if (button.classList.contains('--next')) {
        button.addEventListener('click', () => {
          this.swiper.slideNext(this.swiperSpeed, false);
        });
      } else {
        button.addEventListener('click', () => {
          this.swiper.slidePrev(this.swiperSpeed, false);
        });
      }
    })

    this.swiper.on('slideChange', () => {
      this.swiperButtons.forEach(this.updateButtonState.bind(this));
    });

    this.swiper.on('autoplayStop', () => {
      this.swiperPause(this.autoPlayBtn)
    });
  }

  swiperPlayPause() {
    if (!this.el.hasAttribute('data-autoplay')) return
    this.autoPlayBtn.addEventListener('click', () => {
      if(this.isAutoPlay) {
        this.swiper.autoplay.stop();
        this.swiperPause(this.autoPlayBtn);
      } else {
        this.swiper.autoplay.start();
        this.swiperPlay(this.autoPlayBtn);
      }
    });
  }

  swiperPlay(el) {
    this.isAutoPlay = true;
    el.setAttribute('aria-label', 'pause slider');
    el.querySelector('span').classList.add('--playing');
  }

  swiperPause(el) {
    this.isAutoPlay = false;
    el.setAttribute('aria-label', 'play slider');
    el.querySelector('span').classList.remove('--playing');
  }

  swiperUpdate() {
    if (!this.swiper) return
    if (this.config.loop) return;
    this.swiper.slideTo(0, this.swiperSpeed, false);
    const items = this.el.querySelectorAll('.carousel__item');
    if (items && items.length != 0) {
      items.forEach((item, counter) => item.setAttribute('data-index', counter));
    }
  }

}

export default Carousel;